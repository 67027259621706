import { useRouter } from "next/router";

/**
 * Returns true if the current path is the same as a relative href. Useful for indicating active links in the UI.
 * @param href
 * @returns boolean
 */
export const useHrefActive = (href?: string) => {
  const { pathname } = useRouter();

  return pathname === href;
};
