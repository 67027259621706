import { twMerge } from "tailwind-merge";
import React from "react";

interface SectionClipProps {
  /** The Svg that defines the shape of the divider */
  SvgElement: React.FC<React.SVGProps<SVGSVGElement>>;
  /** Whether the graphic should be flipped or not */
  flipX?: boolean;
  /** An optional className to be applied */
  className?: string;
}

/**
 * This component is used to clip a section with an SVG element to create nice transitions between sections.
 *
 * @example
 * <SectionClip SvgElement={SvgElement} />
 */
export const SectionClip: React.FC<SectionClipProps> = ({
  SvgElement,
  flipX = false,
  className = "",
}) => {
  return (
    <div className={twMerge("pointer-events-none w-full", className)}>
      {<SvgElement className={flipX ? "-scale-x-100" : ""} />}
    </div>
  );
};
