import React, { useState } from "react";
import { useFormik } from "formik";
import { AnimatedHeight } from "components/AnimatedHeight";
import { RecaptchaV2 } from "components/RecaptchaV2";
import { Input } from "components/form-elements/Input";
import { Checkbox } from "components/form-elements/Checkbox";
import { z } from "zod";
import { subscribeToNewsLetter } from "api/newsletter";
import { ButtonAnimated } from "@brightstarstudios/components";
import { NewsLetterSubmissionSchema } from "schemas/newsletter";
import Link from "next/link";
import { TextPoppins } from "@brightstarstudios/components";
import { withZodSchema } from "formik-validator-zod";
import { displayError } from "utils/displayError";

// The validation for this form is the same as the NewsLetterSubmissionSchema, but with an additional field 'consent'
const FormValidationSchema = NewsLetterSubmissionSchema.extend({
  consent: z.boolean(),
  consentTC: z.boolean(),
});

interface FormNewsletterSignupProps
  extends React.HTMLAttributes<HTMLDivElement> {
  inputColor: "white" | "light-gray";
  checkboxColor: "white" | "black";
  buttonAnimationColor: "red" | "blue";
}
export const FormNewsletterSignup: React.FC<FormNewsletterSignupProps> = ({
  inputColor,
  checkboxColor,
  buttonAnimationColor: buttonType,
}) => {
  const [showRecaptcha, setShowRecaptcha] = useState(false);

  const {
    setFieldValue,
    dirty,
    handleSubmit,
    status,
    isSubmitting,
    values,
    handleChange,
    isValid,
  } = useFormik({
    initialValues: {
      email: "",
      consent: false,
      consentTC: false,
      recaptchaToken: "",
    },
    initialStatus: false,
    validate: withZodSchema(FormValidationSchema),
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        const { email, recaptchaToken } = values;

        const data = NewsLetterSubmissionSchema.parse({
          email,
          recaptchaToken,
        });

        const result = await subscribeToNewsLetter(data);

        setStatus(result);
      } catch (error) {
        displayError(error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const loadRecaptcha =
    values.email.length > 0 &&
    values.consent &&
    !isSubmitting &&
    !status &&
    values.consentTC &&
    dirty;

  return (
    <form onSubmit={handleSubmit} className="mx-auto max-w-[500px]">
      <Input
        color={inputColor}
        type="email"
        name="email"
        disabled={isSubmitting || status}
        placeholder="E-mail"
        value={values.email}
        required
        onChange={handleChange}
      />
      <label className="my-6 flex cursor-pointer items-center gap-3">
        {checkboxColor === "white" ? (
          <Checkbox
            color="WHITE"
            name="consent"
            disabled={isSubmitting || status}
            checked={values.consent}
            onChange={handleChange}
          />
        ) : (
          <Checkbox
            color="GRAY"
            name="consent"
            disabled={isSubmitting || status}
            checked={values.consent}
            onChange={handleChange}
          />
        )}

        <TextPoppins size="medium">
          I agree to receive offers, news, and updates about Ember Sword
        </TextPoppins>
      </label>
      <label className="my-6 flex cursor-pointer items-center gap-3">
        {checkboxColor === "white" ? (
          <Checkbox
            color="WHITE"
            name="consentTC"
            disabled={isSubmitting || status}
            checked={values.consentTC}
            onChange={handleChange}
          />
        ) : (
          <Checkbox
            color="GRAY"
            name="consentTC"
            disabled={isSubmitting || status}
            checked={values.consentTC}
            onChange={handleChange}
          />
        )}

        <TextPoppins size="medium">
          I agree to accept the{" "}
          <Link
            className="text-white"
            target="_blank"
            href="/terms-and-conditions"
          >
            terms & conditions
          </Link>{" "}
          and{" "}
          <Link className="text-white" target="_blank" href="/privacy-policy">
            privacy policy.
          </Link>
        </TextPoppins>
      </label>
      <AnimatedHeight
        show={showRecaptcha}
        className="mb-4 flex justify-center overflow-hidden"
      >
        {loadRecaptcha && (
          <RecaptchaV2
            theme="light"
            asyncScriptOnLoad={() => setShowRecaptcha(true)}
            onChange={(token) => {
              setFieldValue("recaptchaToken", token);
            }}
            onExpired={() => {
              setFieldValue("recaptchaToken", null);
            }}
          />
        )}
      </AnimatedHeight>

      {/* The submit button will be shown until the form is successful */}
      <AnimatedHeight show={!status} className="mt-6 flex justify-center">
        <ButtonAnimated
          color={buttonType}
          type="submit"
          disabled={
            !isValid ||
            isSubmitting ||
            !dirty ||
            !values.consent ||
            !values.consentTC ||
            values.recaptchaToken.length === 0
          }
          label={isSubmitting ? `Please wait` : "Submit"}
        />
      </AnimatedHeight>

      {/* When the form is successfully submitted (status==true) this will be shown */}
      <AnimatedHeight show={status} className="mt-6">
        <p className="mb-8 font-poppins text-sm leading-relaxed lg:text-base">
          <strong>IMPORTANT:</strong> To finish your registration, you must
          confirm your subscription via the email we just sent you.
        </p>
        <p className="font-poppins text-sm leading-relaxed lg:text-base">
          You have <strong>2 minutes</strong> to confirm your subscription.
          Remember to check your spam folder. We do this to avoid spam.
        </p>
      </AnimatedHeight>
    </form>
  );
};
