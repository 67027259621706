import React from "react";
import { Modal } from "../Modal";
import { Prose } from "components/Prose";

export const ModalResubscribedToNewsletter: React.FC = () => (
  <Modal.Info>
    <Prose className="text-center">
      <p>
        <strong>Thanks for staying subscribed</strong>
      </p>
      <p>
        We look forward to sending you more exciting news about Ember Sword!
      </p>
    </Prose>
  </Modal.Info>
);
