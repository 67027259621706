import { GlobalSettings } from "types/global-settings";

export const GLOBAL_SETTINGS_DEFAULTS: GlobalSettings = {
  globalBanner: {
    text: "",
    show: false,
    userCanHide: true,
  },
  imxOpen: false,
  premiumShopOpen: true,
  cosmeticMergingOpen: true,
};
