import "styles/global.css";
import "react-toastify/dist/ReactToastify.css";
import type { AppProps } from "next/app";
import { Providers } from "providers/Providers";
import { useFacebookPixel } from "hooks/useFacebookPixel";
import { ToastContainer } from "react-toastify";
import { GlobalPageScripts } from "components/GlobalPageScripts";
import Head from "next/head";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Layout } from "components/globals/Layout";
import { useCookiebot } from "hooks/useCookiebot";

import { useTailwindSmoothScroll } from "hooks/useSmoothScroll";

const App: React.FC<AppProps> = ({ Component, pageProps }) => {
  const { allowAllCookies } = useCookiebot();

  useFacebookPixel(allowAllCookies);
  useTailwindSmoothScroll();

  return (
    <>
      <Head>
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />

        <meta
          name="description"
          content="Ember Sword is a Free-to-Play Massively Multiplayer Online Role-playing Game (MMORPG) taking place in a player-driven dystopian fantasy universe with a classless and fast-paced combat system."
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0"
        />
        <meta charSet="utf-8" />

        <meta property="og:title" content="Ember Sword" />
        <meta property="og:type" content="website" />

        <meta
          property="og:image"
          content="https://embersword.com/images/opengraph-image.jpg"
        />
        <meta
          property="og:image:secure_url"
          content="https://embersword.com/images/opengraph-image.jpg"
        />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:alt" content="Ember Sword landscape" />
        <meta property="og:image:width" content="1024" />
        <meta property="og:image:height" content="619" />

        <meta property="twitter:site" content="@PlayEmberSword" />
        <meta property="twitter:site:id" content="@PlayEmberSword" />
        <meta property="twitter:creator" content="@PlayEmberSword" />
        <meta property="twitter:creator:id" content="@PlayEmberSword" />
        <meta
          name="facebook-domain-verification"
          content="ed4ts6zyn12y8wacun4vebhm285076"
        />
      </Head>
      <Providers>
        <GlobalPageScripts />
        <Layout>
          <Component {...pageProps} />
        </Layout>
        <ToastContainer
          autoClose={5000}
          closeButton={false}
          progressClassName="toast-progress"
        />
        <ReactQueryDevtools initialIsOpen={false} />
      </Providers>
    </>
  );
};

export default App;
