import router from "next/router";
import { useCallback, useEffect } from "react";
import ReactPixel from "react-facebook-pixel";
import { IS_DEV_ENVIRONMENT } from "utils/isDevEnvironment";

export const useFacebookPixel = (allowTracking = false) => {
  const runFacebookPixel = useCallback(() => {
    import("react-facebook-pixel")
      .then((x) => x.default)
      .then((rPixel: typeof ReactPixel) => {
        rPixel.init("459884298593197", undefined, {
          autoConfig: true,
          debug: IS_DEV_ENVIRONMENT,
        });

        rPixel.pageView();

        router.events.on("routeChangeComplete", () => {
          rPixel.pageView();
        });
      });
  }, []);

  useEffect(() => {
    if (allowTracking) {
      runFacebookPixel();
      return router.events.off("routeChangeComplete", runFacebookPixel);
    }
  }, [allowTracking, runFacebookPixel]);
};
