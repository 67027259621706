import { twMerge } from "tailwind-merge";
import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ButtonSimple } from "@brightstarstudios/components";
import { useModal } from "hooks/useModal";
import { BorderBox } from "./BorderBox";

interface ModalCoreProps {
  show: boolean;
  onClose: () => void;
  galleryMode?: boolean;
  showCloseButton?: boolean;
  className?: string;
  children?: React.ReactNode;
}

const ModalCore: React.FC<ModalCoreProps> = ({
  show = false,
  onClose,
  children,
  galleryMode = false,
  className = "",
  showCloseButton = false,
}) => {
  const { hideModal } = useModal();

  const overlayOpacity = galleryMode ? "opacity-90" : "opacity-50";
  const containerWidth = galleryMode ? "w-full" : "";

  return (
    <Transition appear show={show} as={React.Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        open={show}
        onClose={onClose}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-500"
            enterFrom="opacity-0"
            enterTo={overlayOpacity}
            leave="ease-in duration-500"
            leaveFrom={overlayOpacity}
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className={twMerge("fixed inset-0 bg-black", overlayOpacity)}
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="scale-95 translate-y-8"
            enterTo="scale-100 translate-y-0"
            leave="ease-in duration-300"
            leaveFrom="scale-100 translate-y-0"
            leaveTo="scale-95 translate-y-8"
          >
            <div
              className={twMerge(
                "z-10 my-8 inline-block max-w-full transform overflow-hidden rounded-xl text-left align-middle shadow-xl transition-all md:max-w-3xl lg:max-w-4xl xl:max-w-6xl",
                className,
                containerWidth,
              )}
            >
              {children}
            </div>
          </Transition.Child>
        </div>
        {showCloseButton && (
          <ButtonSimple
            color="white"
            className="absolute right-4 top-4 text-2xl"
            onClick={hideModal}
          >
            &times;
          </ButtonSimple>
        )}
      </Dialog>
    </Transition>
  );
};

const ModalContainer: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  className = "",
  ...rest
}) => (
  <div className={twMerge("bg-white", className)} {...rest}>
    {children}
  </div>
);

const ModalInfo: React.FC<{ children?: React.ReactNode }> = ({ children }) => (
  <Modal.Container>
    <BorderBox className="flex max-w-2xl flex-col justify-center p-8 text-extra-100">
      <div className="p-8">{children}</div>
    </BorderBox>
  </Modal.Container>
);

export const Modal = {
  Core: ModalCore,
  Container: ModalContainer,
  Info: ModalInfo,
};
