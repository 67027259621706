import { revokeUserToken } from "api/account";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useRouter } from "next/router";
import { useAuthCookie } from "./useAuthCookie";
import { QUERY_KEYS } from "api/queryKeys";

export const useLogout = () => {
  const router = useRouter();
  const { authToken, removeAuthCookie } = useAuthCookie();
  const queryClient = useQueryClient();

  const {
    mutate: logout,
    isPending: logoutIsLoading,
    isSuccess: logoutIsSuccess,
  } = useMutation({
    mutationFn: async () => await revokeUserToken(authToken),
    onMutate: async () => {
      queryClient.setQueryData([QUERY_KEYS.USER], null);
      queryClient.setQueryData([QUERY_KEYS.ALLOWED_TO_PLAY], false);
      // Start navigation to home page before the request is sent
      router.replace("/").catch((e) => {
        // workaround for https://github.com/vercel/next.js/issues/37362
        if (!e.cancelled) {
          throw e;
        }
      });
    },
    onSettled: () => {
      // Regardless of the outcome of the revokeUserToken request, clear the auth cookie
      removeAuthCookie();
    },
  });

  return {
    logout,
    logoutIsLoading,
    logoutIsSuccess,
  };
};
