import { clientConfig } from "config/client";
import { GLOBAL_SETTINGS_DEFAULTS } from "data/default-global-settings";
import { GlobalSettingschema } from "schemas/global-settings";
import { GlobalSettings } from "types/global-settings";

// Get global settings
export const getGlobalSettings = async (): Promise<GlobalSettings> => {
  const res = await fetch(clientConfig.globalSettingsUrl);

  // Return defaults
  if (!res.ok) {
    return GLOBAL_SETTINGS_DEFAULTS;
  }

  const data = GlobalSettingschema.safeParse(await res.json());

  if (!data.success) {
    // The response from the Ashby api is 200, but not the shape we expect
    console.warn(
      `Returning global default settings due to schema validation error`,
      { cause: data.error },
    );
    return GLOBAL_SETTINGS_DEFAULTS;
  }

  return data.data;
};
