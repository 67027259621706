import { Input } from "components/form-elements/Input";
import { Formik, Form } from "formik";
import React, { useState } from "react";
import Link from "next/link";
import { getModalRouteLink } from "utils/getModalRouteLink";
import { AnimatedHeight } from "components/AnimatedHeight";
import { RecaptchaV2 } from "components/RecaptchaV2";
import {
  BrightStarEmailSchema,
  EmailSchema,
  PasswordSchema,
} from "schemas/account-api";
import { ButtonAnimated } from "@brightstarstudios/components";
import { loginUser } from "api/account";
import { LoginResponseSuccess, LoginUserParams } from "types/account-api";
import { TextPoppins } from "@brightstarstudios/components";

const INITIAL_VALUES = {
  email: "",
  password: "",
  recaptcha: undefined,
} as const;

interface FormLoginProps {
  /** Called when the login request starts */
  onLoginStart?: (loginUserParams: LoginUserParams) => void;
  /** Called if the login request succeeds */
  onLoginSuccess?: (loginResponse: LoginResponseSuccess) => void;
  /** Called if the login request fails */
  onLoginError?: (error: unknown) => void;
}

/**
 * Form for logging users in.
 * It will validate the email and password fields and if email is not a brightstar email, it will also require a recaptcha field to be filled out.
 *
 * @example
 * <FormLogin
 *  onLoginSuccess={(loginResponse) => {
 *   // Do something with the login response
 *  }}
 * />
 */
export const FormLogin: React.FC<FormLoginProps> = ({
  onLoginStart = () => {},
  onLoginSuccess = () => {},
  onLoginError = () => {},
}) => {
  const [showRecaptcha, setShowRecaptcha] = useState(false);

  return (
    <Formik<LoginUserParams>
      initialValues={INITIAL_VALUES}
      validateOnMount
      validate={(values) => {
        const errors: Record<string, string> = {};

        const emailParse = EmailSchema.safeParse(values.email);
        if (!emailParse.success) {
          errors.email = emailParse.error.issues
            .map((issue) => issue.message)
            .join("\n");
        }

        const passwordParse = PasswordSchema.safeParse(values.password);
        if (!passwordParse.success) {
          errors.password = passwordParse.error.issues
            .map((issue) => issue.message)
            .join("\n");
        }

        return errors;
      }}
      onSubmit={async (values, { setFieldError }) => {
        onLoginStart(values);

        // @brightstar.studio email addresses get to login without recaptcha
        if (
          !values.recaptcha &&
          !BrightStarEmailSchema.safeParse(values.email).success
        ) {
          setFieldError("recaptcha", "Please verify you are not a robot");
          onLoginError("Please verify you are not a robot");
          return;
        }

        try {
          const res = await loginUser({
            email: values.email,
            password: values.password,
            recaptcha: values.recaptcha,
          });
          onLoginSuccess(res);
        } catch (error) {
          console.error(error);
          onLoginError(error);
        }
      }}
    >
      {({
        values,
        handleChange,
        isValid,
        setFieldValue,
        isSubmitting,
        dirty,
      }) => {
        const loadRecaptcha =
          isValid &&
          dirty &&
          !BrightStarEmailSchema.safeParse(values.email).success;

        return (
          <Form className="flex w-full flex-col">
            <Input
              color="light-gray"
              id="login-email-input"
              className="mb-6"
              type="email"
              name="email"
              placeholder="E-mail"
              value={values.email}
              onChange={handleChange}
            />
            <Input
              color="light-gray"
              id="login-password-input"
              className="mb-6"
              type="password"
              name="password"
              autoComplete="on"
              placeholder="Password"
              value={values.password}
              onChange={handleChange}
            />

            <TextPoppins size="small">
              <Link
                href={getModalRouteLink("forgot-password")}
                className="block text-center uppercase text-primary-red"
              >
                Forgot password?
              </Link>
            </TextPoppins>

            <AnimatedHeight
              show={showRecaptcha}
              className="mt-2 flex justify-center overflow-hidden"
            >
              {loadRecaptcha && (
                <RecaptchaV2
                  className="mt-4"
                  theme="light"
                  asyncScriptOnLoad={() => setShowRecaptcha(true)}
                  onChange={(token) => {
                    setFieldValue("recaptcha", token);
                  }}
                  onExpired={() => {
                    setFieldValue("recaptcha", undefined);
                  }}
                />
              )}
            </AnimatedHeight>
            <div className="mb-4 flex justify-center">
              <ButtonAnimated
                color="red"
                id="login-submit-button"
                disabled={!isValid || isSubmitting || !dirty}
                className="my-4"
                label={isSubmitting ? "Waiting..." : "Login"}
                type="submit"
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
