import React from "react";
import { HTMLMotionProps, motion } from "framer-motion";

interface AnimatedHeightProps extends HTMLMotionProps<"div"> {
  show: boolean;
}

export const AnimatedHeight: React.FC<AnimatedHeightProps> = ({
  show = false,
  children,
  ...rest
}) => {
  return (
    <motion.div
      style={{ pointerEvents: show ? "auto" : "none" }}
      initial={{ height: 0, opacity: 0 }}
      animate={{ height: show ? "auto" : 0, opacity: show ? 1 : 0 }}
      {...rest}
    >
      {children}
    </motion.div>
  );
};
