import { twMerge } from "tailwind-merge";
import React from "react";

import { NavBarUnderline } from "./NavBarUnderline";
import { useHrefActive } from "hooks/useHrefActive";

interface NavBarButtonProps extends React.HTMLAttributes<HTMLSpanElement> {
  href?: string;
  active?: boolean;
}

export const NavBarButton: React.FC<NavBarButtonProps> = ({
  href,
  children,
  active = false,
  className = "",
  ...rest
}) => {
  const isActive = useHrefActive(href) || active;

  const textColor = isActive ? "text-primary-red" : "text-tertiary-600";

  return (
    <span
      className={twMerge(
        "group group relative flex items-center justify-center whitespace-nowrap text-center font-extrabold uppercase transition-colors duration-300 hover:text-primary-red",
        className,
        textColor,
      )}
      {...rest}
    >
      {children}
      <NavBarUnderline
        active={isActive}
        className="absolute -bottom-2 left-0 w-full text-primary-red"
      />
    </span>
  );
};
