import { twMerge } from "tailwind-merge";
import React from "react";

export const Prose: React.FC<React.HTMLAttributes<HTMLElement>> = ({
  className = "",
  children,
  ...rest
}) => {
  return (
    <div
      className={twMerge(
        "prose max-w-none font-poppins text-sm font-medium leading-relaxed tracking-wide text-tertiary-600 marker:text-primary-red prose-headings:font-jost prose-headings:uppercase prose-headings:text-primary-red prose-h2:text-3xl prose-p:my-6 prose-a:text-primary-red prose-a:transition-colors prose-a:duration-300 hover:prose-a:text-primary-red/80 prose-figure:my-12 prose-figure:flex prose-figure:flex-col prose-figure:items-center prose-figcaption:text-extra-100 prose-strong:text-tertiary-600",
        className,
      )}
      {...rest}
    >
      {children}
    </div>
  );
};
