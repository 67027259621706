import { twMerge } from "tailwind-merge";
import React, { useState } from "react";
import Link from "next/link";
import { SectionClip } from "components/sections/SectionClip";
import { SOCIALS } from "data/socials";
import { Container } from "components/Container";
import { IndicatedIcon } from "components/IndicatedIcon";
import SvgBarren from "assets/svgs/section-clips/barren.svg";
import SvgDividerClosing from "assets/svgs/decorations/divider-closing.svg";
import SvgUp from "assets/svgs/icons/up.svg";
import SvgEmberswordLogo from "assets/svgs/embersword-logo.svg";
import { FOOTER_MENU_ITEMS } from "data/nav-data";

// Thin wrapper around IndicatedIcon to add hover effect
const AnchorIndicatedIcon: React.FC<{
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  href: string;
}> = ({ Icon, href }) => {
  const [showIndicator, setShowIndicator] = useState<boolean>(false);
  const iconClass = showIndicator ? "text-primary-red" : "";

  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      <IndicatedIcon
        Icon={Icon}
        iconClassName={twMerge("duration-500", iconClass)}
        showIndicator={showIndicator}
        indicatorClassName={"text-primary-red"}
        className="block h-16 w-16"
        onMouseOver={() => setShowIndicator(true)}
        onMouseLeave={() => setShowIndicator(false)}
      />
    </a>
  );
};

export const Footer: React.FC = () => {
  return (
    <footer className="relative w-full bg-primary-grey">
      <SectionClip
        SvgElement={SvgBarren}
        className="absolute inset-x-0 top-1 z-30 -translate-y-full fill-primary-grey"
      />
      <Container className="py-12">
        <SvgDividerClosing className="text-teal-700 mb-6" />

        <div className="grid grid-cols-12 gap-4">
          <Link href="/" className="col-span-12 lg:col-span-4 lg:col-start-5">
            <SvgEmberswordLogo className="mx-auto max-w-sm text-tertiary-600" />
          </Link>

          <div className="col-span-12 self-center justify-self-center lg:col-span-4 lg:justify-self-end">
            <Link href="#" className="flex flex-row items-center gap-x-2">
              <p className="font-jost font-extrabold uppercase">
                Rise To The Top
              </p>
              <SvgUp className="fill-primary-red" width={35} height={35} />
            </Link>
          </div>
        </div>

        <div className="py-10 text-tertiary-600">
          <div className="grid grid-cols-12 gap-4 text-center">
            {FOOTER_MENU_ITEMS.map(({ name, link, external }) => {
              if (external) {
                return (
                  <a
                    key={name}
                    className="col-span-12 cursor-pointer font-poppins font-bold uppercase transition-colors hover:text-primary-red lg:col-span-4 xl:col-span-3"
                    rel="noreferrer noopener"
                    target="_blank"
                    href={link}
                  >
                    {name}
                  </a>
                );
              }

              return (
                <Link
                  key={name}
                  className="col-span-12 cursor-pointer font-poppins font-bold uppercase transition-colors hover:text-primary-red lg:col-span-4 xl:col-span-3"
                  href={link}
                >
                  {name}
                </Link>
              );
            })}
          </div>

          <div className="mt-12 flex flex-wrap justify-center gap-4">
            {SOCIALS.map(({ link, Icon }) => (
              <AnchorIndicatedIcon key={link} Icon={Icon} href={link} />
            ))}
          </div>
        </div>
      </Container>
    </footer>
  );
};
