import React from "react";
import Script from "next/script";
import { useCookiebot } from "hooks/useCookiebot";
import { GoogleAnalytics } from "@next/third-parties/google";

export const GlobalPageScripts: React.FC = () => {
  const { allowAllCookies } = useCookiebot();

  return (
    <>
      <Script
        id="Cookiebot"
        src="https://consent.cookiebot.com/uc.js"
        data-cbid="48fdaf97-bc73-4c69-9107-2cf7657a98fc"
        data-blockingmode="auto"
        type="text/javascript"
      />
      {allowAllCookies && (
        <Script
          id="Ortto"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
     window.ap3c = window.ap3c || {};
     var ap3c = window.ap3c;
     ap3c.cmd = ap3c.cmd || [];
     ap3c.cmd.push(function() {
         ap3c.init('YdawGzhT7gSzT6t2YnJpZ2h0c3RhcnN0dWRpb3M', 'https://capture-api.autopilotapp.com/');
         ap3c.track({v: 0});
     });
     ap3c.activity = function(act) { ap3c.act = (ap3c.act || []); ap3c.act.push(act); };
     var s, t; s = document.createElement('script'); s.type = 'text/javascript'; s.src = "https://cdn3l.ink/app.js";
     t = document.getElementsByTagName('script')[0]; t.parentNode.insertBefore(s, t);
   `,
          }}
        />
      )}
      <GoogleAnalytics gaId="G-HBSE8EVG3X" />
      {/* <Script src="https://www.googletagmanager.com/gtag/js?id=G-HBSE8EVG3X" /> */}
      {/* <Script id="google-analytics">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
 
          gtag('config', 'G-HBSE8EVG3X');
        `}
      </Script> */}
    </>
  );
};
