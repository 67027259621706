import { Modal } from "components/Modal";
import { ModalType, ModalTypeSchema } from "types/modals";
import React, { useEffect, useState } from "react";
import { ModalLogin } from "components/modal-content/ModalLogin";
import { useRouter } from "next/router";
import { useModal } from "hooks/useModal";
import { ModalForgotPassword } from "components/modal-content/ModalForgotPassword";
import { ModalResetPassword } from "components/modal-content/ModalResetPassword";
import { ModalCreateAccount } from "components/modal-content/ModalCreateAccount";
import { ModalAccountActivaction } from "components/modal-content/ModalAccountActivaction";
import { ModalCreateAccountInfo } from "components/modal-content/ModalCreateAccountInfo";
import { ModalImage } from "components/modal-content/ModalImage";
import { ModalVideo } from "components/modal-content/ModalVideo";
import { ModalNewsletterInterest } from "components/modal-content/ModalNewsletterInterest";
import { ModalNewsletterSignup } from "components/modal-content/ModalNewsletterSignup";
import { ModalResubscribedToNewsletter } from "components/modal-content/ModalResubscribedToNewsletter";
import { ModalPageClosed } from "components/modal-content/ModalPageClosed";
import { ModalDeleteAccount } from "components/modal-content/ModalDeleteAccount";
import { ModalAssetTransfer } from "components/modal-content/ModalTransferImxNft";
import { ModalBadgeExchangeForTokens } from "components/modal-content/ModalBadgeExchangeForTokens";
import { getHashParamFromUrl } from "@brightstarstudios/utilities";
import { ModalBadgeBurnedSuccess } from "components/modal-content/ModalBadgeBurnedSuccess";
import { ModalBetaDisclaimer } from "components/modal-content/ModalBetaDisclaimer";

export const ModalProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [galleryMode, setGalleryMode] = useState(false);
  const { hideModal } = useModal();
  const router = useRouter();

  const [modalOpen, setModalOpen] = useState<ModalType | null>(null);

  useEffect(() => {
    if (!router.isReady) return; // Don't run this code until the router is ready

    // Get the modal type from the URL hash params
    const modalHashParam = getHashParamFromUrl("modal", router.asPath);

    // Check if we have a modal in the URL hash params. If not, close the side panel.
    if (!modalHashParam) {
      setModalOpen(null);
      return;
    }

    // Trying to open a modal we don't have. Redirect to 404 page.
    const modalTypeParse = ModalTypeSchema.safeParse(modalHashParam);
    if (!modalTypeParse.success) {
      setModalOpen(null);
      router.replace("/404").catch((e) => {
        // workaround for https://github.com/vercel/next.js/issues/37362
        if (!e.cancelled) {
          throw e;
        }
      });
      return;
    }

    // We have a valid side panel type
    const modalType = modalTypeParse.data;
    setModalOpen(modalType);

    const enableGalleryMode = modalType === "video" || modalType === "image"; // Add darker faded background when modal is with media content: ;
    setGalleryMode(enableGalleryMode);
  }, [router]);

  return (
    <>
      {children}
      <Modal.Core
        show={!!modalOpen}
        onClose={hideModal}
        galleryMode={galleryMode}
        showCloseButton={galleryMode}
      >
        {modalOpen && <ModalElem modalType={modalOpen} />}
      </Modal.Core>
    </>
  );
};

type ModalElemProps = {
  modalType: ModalType;
};

const ModalElem: React.FC<ModalElemProps> = ({ modalType }) => {
  switch (modalType) {
    case "login":
      return <ModalLogin />;
    case "forgot-password":
      return <ModalForgotPassword />;
    case "reset-password":
      return <ModalResetPassword />;
    case "create-account":
      return <ModalCreateAccount />;
    case "account-activation":
      return <ModalAccountActivaction />;
    case "create-account-info":
      return <ModalCreateAccountInfo />;
    case "image":
      return <ModalImage />;
    case "video":
      return <ModalVideo />;
    case "newsletter-interest":
      return <ModalNewsletterInterest />;
    case "newsletter-signup":
      return <ModalNewsletterSignup />;
    case "resubscribe-newsletter":
      return <ModalResubscribedToNewsletter />;
    case "page-closed":
      return <ModalPageClosed />;
    case "beta-disclaimer":
      return <ModalBetaDisclaimer />;
    case "delete-account":
      return <ModalDeleteAccount />;
    case "asset-transfer":
      return <ModalAssetTransfer />;
    case "badge-exchange-tokens":
      return <ModalBadgeExchangeForTokens />;
    case "badge-burned-success":
      return <ModalBadgeBurnedSuccess />;
  }
};
