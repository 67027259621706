import { allowedToPlay } from "api/account";
import { QUERY_KEYS } from "api/queryKeys";
import { useQuery } from "@tanstack/react-query";
import { useAuthCookie } from "./useAuthCookie";

export const useAllowedToPlay = () => {
  const { authToken } = useAuthCookie();

  return useQuery({
    queryKey: [QUERY_KEYS.ALLOWED_TO_PLAY, authToken],
    queryFn: () => {
      if (!authToken) {
        return false;
      }

      return allowedToPlay(authToken);
    },
  });
};
