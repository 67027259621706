import { twMerge } from "tailwind-merge";
import { useId } from "react";

interface NavBarUnderlineProps extends React.HTMLAttributes<HTMLSpanElement> {
  active: boolean;
  className?: string;
}

export const NavBarUnderline: React.FC<NavBarUnderlineProps> = ({
  active = false,
  className = "text-tertiary-600",
}) => {
  const leftMaskClass = active
    ? "translate-x-[-55%]"
    : "group-hover:translate-x-[-55%]";
  const rightMaskClass = active
    ? "translate-x-[55%]"
    : "group-hover:translate-x-[55%]";

  // Animation doesn't trigger if masks doesn't have a unique id in the DOM
  const componentId = useId();

  return (
    <svg
      className={twMerge("transform-gpu", className)}
      viewBox="0 0 100 10"
      height={7}
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <mask id={`left-mask-${componentId}`}>
          <rect x="0" y="0" width="100%" height="100%" fill="white" />
          <polygon
            points="-1,-1 -1,11 57,11 50,-1"
            fill="black"
            className={twMerge(
              "translate-x-0 transition-transform duration-300",
              leftMaskClass,
            )}
          />
        </mask>
        <mask id={`right-mask-${componentId}`}>
          <rect x="0" y="0" width="100%" height="100%" fill="white" />
          <polygon
            points="50,-1 43,11 101,11 101,-1"
            fill="black"
            className={twMerge(
              "translate-x-0 transition-transform duration-300",
              rightMaskClass,
            )}
          />
        </mask>
      </defs>
      {/* Inner polygons */}
      <polygon
        points="26,0 25,2 29,10 51,10 51,0"
        fill="currentColor"
        mask={`url(#left-mask-${componentId})`}
      />
      <polygon
        points="49,0 49,10 71,10 75,2 74,0"
        fill="currentColor"
        mask={`url(#right-mask-${componentId})`}
      />
      {/* Outer polygons */}
      <polygon
        points="4,0 0,8 1,10 24,10 25,8 21,0"
        fill="currentColor"
        mask={`url(#left-mask-${componentId})`}
      />
      <polygon
        points="79,0 75,8 76,10 99,10 100,8 96,0"
        fill="currentColor"
        mask={`url(#right-mask-${componentId})`}
      />
    </svg>
  );
};
