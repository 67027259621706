import { twMerge } from "tailwind-merge";
import { NavBarButton } from "components/NavBarButton";
import { Portal } from "components/Portal";
import { PRIMARY_MENU_ITEMS } from "data/nav-data";
import { useMobileMenu } from "hooks/useMobileMenu";
import Link from "next/link";

export const Mobile = () => {
  const { open, toggle } = useMobileMenu();

  const show = open ? "block" : "hidden";

  const menuButtonClass = open ? "-rotate-45" : "";

  return (
    <>
      <div className="flex gap-x-8 lg:hidden">
        <button
          onClick={toggle}
          id="mobile-menu-button"
          className="group relative z-50 h-10 w-10 cursor-pointer rounded-full border-2 border-tertiary-600 transition-colors duration-300 hover:bg-tertiary-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-red"
        >
          <div
            className={twMerge(
              "flex flex-col items-center justify-center gap-1 rounded transition-transform duration-150",
              menuButtonClass,
            )}
          >
            <div className="h-1 w-3 rounded-full bg-tertiary-600 transition-colors duration-300 group-hover:bg-white" />
            <div className="h-1 w-5 rounded-full bg-tertiary-600 transition-colors duration-300 group-hover:bg-white" />
            <div className="h-1 w-3 rounded-full bg-tertiary-600 transition-colors duration-300 group-hover:bg-white" />
          </div>
        </button>
      </div>
      <Portal>
        <div
          className={twMerge(
            "fixed left-0 top-16 z-40 flex h-full w-full items-center justify-center bg-white pt-8 text-white lg:hidden",
            show,
          )}
        >
          <nav>
            <ul className="flex flex-col items-center justify-center gap-y-4 text-center">
              {PRIMARY_MENU_ITEMS.map(({ name, external, link }) => {
                const externalProps = external && {
                  target: "_blank",
                  rel: "noreferrer noopener",
                };

                return (
                  <li key={link}>
                    <Link
                      href={link}
                      {...externalProps}
                      className="block text-center focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-red"
                    >
                      <NavBarButton href={link}>{name}</NavBarButton>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </nav>
        </div>
      </Portal>
    </>
  );
};
