import { twMerge } from "tailwind-merge";
import React from "react";
import { motion } from "framer-motion";

interface AnimatedPlaceholderProps {
  value?: string;
  placeholder?: string;
  className?: string;
}

export const AnimatedPlaceholder: React.FC<AnimatedPlaceholderProps> = ({
  value,
  placeholder,
  className = "",
}) => {
  const animate = value && value.length > 0 ? "animate" : "normal";

  const bgVariants = {
    animate: {
      width: "auto",
      transition: {
        delayChildren: 0.2,
      },
    },
    normal: {
      width: 0,
    },
  };

  const labelVariants = {
    animate: {
      opacity: 0.5,
    },
    normal: {
      opacity: 0,
    },
  };

  return (
    <motion.div
      variants={bgVariants}
      animate={animate}
      initial={false}
      className={twMerge(
        "pointer-events-none absolute left-3 top-1 flex items-center overflow-hidden whitespace-nowrap bg-transparent px-2 py-0.5 font-poppins text-xs",
        className,
      )}
    >
      <motion.label variants={labelVariants}>{placeholder}</motion.label>
    </motion.div>
  );
};
