import { TitleJost, TextPoppins } from "@brightstarstudios/components";
import SvgEmbersword from "assets/svgs/icons/embersword.svg";
import { FormForgotPassword } from "components/forms/FormForgotPassword";
import Link from "next/link";
import { getModalRouteLink } from "utils/getModalRouteLink";

export const ModalForgotPassword = () => {
  return (
    <div className="w-72 bg-white md:w-96">
      <div className="flex flex-col items-center justify-center bg-primary-red p-8 text-white">
        <SvgEmbersword width={75} height={125} />
        <TitleJost
          size="medium"
          variant="h1"
          className="mt-6 text-center font-extrabold uppercase"
        >
          Account management
        </TitleJost>
        <p className="font-poppins font-semibold uppercase text-white">
          Forgot password
        </p>
      </div>
      <div className="px-4 py-8 lg:p-8">
        <FormForgotPassword />

        <TextPoppins
          size="small"
          className="text-center uppercase text-tertiary-600/60"
        >
          Have an account?
          <Link
            className="mt-2 block text-center uppercase text-primary-red"
            href={getModalRouteLink("login")}
          >
            Login
          </Link>
        </TextPoppins>
      </div>
    </div>
  );
};
