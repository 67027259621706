import { useCallback, useEffect, useState } from "react";

interface CookieBot {
  consent: {
    marketing: boolean;
    necessary: boolean;
  };
  hasResponse: boolean;
  doNotTrack: boolean;
  CookiebotOnAccept: () => void;
  CookiebotOnDecline: () => void;
}

export const useCookiebot = () => {
  const [allowAllCookies, setAllowAllCookies] = useState(false);

  const setCookieConsent = useCallback(() => {
    const cookieBot: CookieBot = (window as any).Cookiebot;

    if (cookieBot && cookieBot.consent) {
      setAllowAllCookies(
        cookieBot.consent.marketing && cookieBot.consent.necessary,
      );
    }
  }, []);

  const declineCookieConsent = useCallback(() => {
    setAllowAllCookies(false);
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const win = window as any;

      setCookieConsent();

      win.addEventListener("CookiebotOnAccept", setCookieConsent);
      win.addEventListener("CookiebotOnDecline", declineCookieConsent);

      return () => {
        win.removeEventListener("CookiebotOnAccept", setCookieConsent);
        win.removeEventListener("CookiebotOnDecline", declineCookieConsent);
      };
    }
  }, [declineCookieConsent, setCookieConsent]);

  return {
    allowAllCookies,
  };
};
