import React, { useState } from "react";
import { ModalProvider } from "./ModalProvider";
import { QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { ParallaxProvider } from "react-scroll-parallax";
import { CookiesProvider } from "react-cookie";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";

export const Providers: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: false,
          },
        },
      }),
  );

  return (
    <CookiesProvider>
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={{
          maxAge: 300000,
          buster: "v1.2.1",
          persister: createSyncStoragePersister({
            storage: typeof window !== "undefined" ? window.localStorage : null,
          }),
        }}
      >
        <ModalProvider>
          <ParallaxProvider>{children}</ParallaxProvider>
        </ModalProvider>
      </PersistQueryClientProvider>
    </CookiesProvider>
  );
};
