import { showToast } from "components/Toast";

/**
 * Display a toast message saying that user's browser does not meet the requirements to play the game.
 */
export const displayRequirementsNotMetToast = () => {
  showToast.warning(
    <>
      The browser and/or device you are using does not meet the requirements for
      the Ember Sword Beta play test.
      <br />
      <br />
      To get the best experience please play on a Windows PC with Google Chrome
      or Microsoft Edge version 108 or newer.
    </>,
    {
      toastId: "gameRequirementsBox",
      autoClose: 15000,
    },
  );
};
