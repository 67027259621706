import { getDomainName } from "@brightstarstudios/utilities";
import { useCookies } from "react-cookie";

export const COOKIE_AUTH_KEY = "x-es-token";
const COOKIE_AUTH_CONFIG_KEY = "x-es-config";

export function useAuthCookie() {
  const [cookies, setCookie, removeCookie] = useCookies();
  const authToken = cookies[COOKIE_AUTH_KEY];

  const setAuthCookie = (token: string) => {
    // We just want the base domain so the cookie is available on sub domains
    const domain = getDomainName();
    setCookie(COOKIE_AUTH_KEY, token, {
      path: "/",
      secure: true,
      maxAge: 60 * 60 * 8, // 8 hours
      domain,
    });
  };

  const removeAuthCookie = () => {
    const domain = getDomainName();
    removeCookie(COOKIE_AUTH_KEY, {
      path: "/",
      domain,
    });

    removeCookie(COOKIE_AUTH_CONFIG_KEY, {
      path: "/",
      domain,
    });
  };

  return { authToken, setAuthCookie, removeAuthCookie };
}
