import { createPortal } from "react-dom";
import { useEffect, useState, ReactNode } from "react";

interface PortalProps {
  children?: ReactNode;
}

export const Portal: React.FC<PortalProps> = ({ children }) => {
  const [mounted, setMounted] = useState(false);
  const portalRoot =
    typeof window !== "undefined" &&
    (document.querySelector("#emberPortal") as HTMLElement);

  useEffect(() => {
    setMounted(true);

    return () => setMounted(false);
  }, []);

  return mounted
    ? createPortal(
        children,
        // @ts-ignore
        portalRoot,
      )
    : null;
};
