export const postBurnBadgesSuccess = async (
  txIds: string[],
  kycRequired: boolean,
) => {
  const url = "/api/burn-badge-success";

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ txIds, kycRequired }),
  });

  if (!response.ok) {
    console.error(
      `Unable to send confirmation e-mail (status ${response.status})`,
    );
  }

  return response;
};
