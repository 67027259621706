import { Social } from "types/social";
import SvgTelegram from "assets/svgs/icons/social/telegram.svg";
import SvgInstagram from "assets/svgs/icons/social/instagram.svg";
import SvgLinkedin from "assets/svgs/icons/social/linkedin.svg";
import SvgFacebook from "assets/svgs/icons/social/facebook.svg";
import SvgMedium from "assets/svgs/icons/social/medium.svg";
import SvgTwitch from "assets/svgs/icons/social/twitch.svg";
import SvgDiscord from "assets/svgs/icons/social/discord.svg";
import SvgTwitter from "assets/svgs/icons/social/twitter.svg";
import SvgYoutube from "assets/svgs/icons/social/youtube.svg";
import SvgReddit from "assets/svgs/icons/social/reddit.svg";
import SvgTiktok from "assets/svgs/icons/social/tiktok.svg";

export const SOCIALS: Social[] = [
  {
    name: "telegram",
    link: "https://t.me/EmberSword",
    Icon: SvgTelegram,
  },
  {
    name: "instagram",
    link: "https://www.instagram.com/officialembersword",
    Icon: SvgInstagram,
  },
  {
    name: "linkedin",
    link: "https://www.linkedin.com/company/brightstar-studios",
    Icon: SvgLinkedin,
  },
  {
    name: "facebook",
    link: "https://www.facebook.com/PlayEmberSword",
    Icon: SvgFacebook,
  },
  {
    name: "medium",
    link: "https://medium.com/embersword",
    Icon: SvgMedium,
  },
  {
    name: "twitch",
    link: "https://www.twitch.tv/EmberSword",
    Icon: SvgTwitch,
  },
  {
    name: "discord",
    link: "https://discord.com/invite/embersword",
    Icon: SvgDiscord,
  },
  {
    name: "twitter",
    link: "https://twitter.com/PlayEmberSword",
    Icon: SvgTwitter,
  },
  {
    name: "youtube",
    link: "https://www.youtube.com/c/EmberSword",
    Icon: SvgYoutube,
  },
  {
    name: "reddit",
    link: "https://www.reddit.com/r/EmberSword",
    Icon: SvgReddit,
  },
  {
    name: "tiktok",
    link: "http://www.tiktok.com/@playembersword",
    Icon: SvgTiktok,
  },
];
