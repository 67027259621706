import { useRouter } from "next/router";
import { ModalType } from "types/modals";
import { getModalRouteLink } from "utils/getModalRouteLink";

export const useModal = () => {
  const router = useRouter();

  const hideModal = () => {
    router
      .replace({ hash: undefined }, undefined, {
        shallow: true,
        scroll: false,
      })
      .catch((e) => {
        // workaround for https://github.com/vercel/next.js/issues/37362
        if (!e.cancelled) {
          throw e;
        }
      });
  };

  const showModal = <T>(modalType: ModalType, modalContent?: T) => {
    const linkRoute = getModalRouteLink(modalType, modalContent);

    router.push(linkRoute, undefined, { shallow: true, scroll: false });
  };

  return { hideModal, showModal };
};
