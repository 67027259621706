import { twMerge } from "tailwind-merge";
import React from "react";

export const Container: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  className = "",
  ...rest
}) => {
  return (
    <div
      className={twMerge("container mx-auto px-3 md:px-6 lg:px-12", className)}
      {...rest}
    >
      {children}
    </div>
  );
};
