import SvgInfoBubble from "assets/svgs/icons/exclamation-diamond.svg";
import { Accordion, AccordionProps } from "components/Accordion";
import { TextPoppins } from "@brightstarstudios/components";
import { useState } from "react";
import { twMerge } from "tailwind-merge";

interface FormErrorsProps extends Omit<AccordionProps, "title"> {
  errors: Record<string, string>;
  touched: Record<string, boolean>;
}

export const FormErrors: React.FC<FormErrorsProps> = ({
  className,
  errors,
  touched,
  ...rest
}) => {
  const formErrors = Object.keys(errors)
    .map((key) => {
      if (touched[key]) {
        return errors[key];
      }

      return;
    })
    .filter((val) => val);

  const [toggleErrors, setToggleErrors] = useState(false);

  if (formErrors.length === 0) {
    return;
  }

  return (
    <Accordion
      active={toggleErrors}
      title={
        <div className="flex items-center">
          <SvgInfoBubble className="mr-2 h-5 w-5" />
          <TextPoppins size="small" className="lowercase">
            {formErrors?.length} errors
          </TextPoppins>
        </div>
      }
      className={twMerge("text-primary-red", className)}
      onChange={() => setToggleErrors(!toggleErrors)}
      {...rest}
    >
      <div className="flex flex-col gap-y-1">
        {formErrors?.map((error) => (
          <TextPoppins size="small" key={error}>
            • {error}
          </TextPoppins>
        ))}
      </div>
    </Accordion>
  );
};
