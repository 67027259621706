import { z } from "zod";

const ClientEnvironmentSchema = z.object({
  NEXT_PUBLIC_ENVIRONMENT: z.enum([
    "development",
    "test",
    "staging",
    "production",
  ]),

  NEXT_PUBLIC_ES_AUTH_API: z.string(),
  NEXT_PUBLIC_GAME_LINK: z.string(),

  NEXT_PUBLIC_GAME_LINK_NORTH_AMERICA: z.string(),
  NEXT_PUBLIC_GAME_LINK_SOUTH_AMERICA: z.string(),
  NEXT_PUBLIC_GAME_LINK_SOUTH_EAST_ASIA: z.string(),
  NEXT_PUBLIC_GAME_LINK_EUROPE: z.string(),

  NEXT_PUBLIC_SALE_API: z.string(),

  NEXT_PUBLIC_POLYGON_LAND_CONTRACT_ID: z.string(),
  NEXT_PUBLIC_POLYGON_BADGE_CONTRACT_ID: z.string(),

  NEXT_PUBLIC_IMX_LAND_CONTRACT_ID: z.string(),
  NEXT_PUBLIC_IMX_BADGE_CONTRACT_ID: z.string(),
  NEXT_PUBLIC_IMX_API_URL: z.string(),
  NEXT_PUBLIC_IMX_LINK_URL: z.string(),
  NEXT_PUBLIC_IMX_MIGRATION_API: z.string(),
  NEXT_PUBLIC_IMX_MARKETPLACE_URL: z.string(),
  NEXT_PUBLIC_USDC_ADDRESS: z.string(),
  NEXT_PUBLIC_TOKEN_RECEIVER_ADDRESS: z.string(),
  NEXT_PUBLIC_SALE_DISCOUNT: z.coerce.number(),
  NEXT_PUBLIC_FRONTEND_CART_EXPIRATION_MS: z.coerce.number(),
  NEXT_PUBLIC_IMX_BURN_BATCH_AMOUNT: z.coerce.number(),
  NEXT_PUBLIC_IMX_BURN_BADGE_AMOUNT: z.coerce.number(),
  NEXT_PUBLIC_THIRD_WEB_CLIENT_ID: z.string(),
  NEXT_PUBLIC_GLOBAL_SETTINGS: z.string().url(),
});

const clientEnvironment = ClientEnvironmentSchema.parse({
  NEXT_PUBLIC_ENVIRONMENT: process.env.NEXT_PUBLIC_ENVIRONMENT,

  NEXT_PUBLIC_ES_AUTH_API: process.env.NEXT_PUBLIC_ES_AUTH_API,
  NEXT_PUBLIC_GAME_LINK: process.env.NEXT_PUBLIC_GAME_LINK,

  NEXT_PUBLIC_GAME_LINK_NORTH_AMERICA:
    process.env.NEXT_PUBLIC_GAME_LINK_NORTH_AMERICA,
  NEXT_PUBLIC_GAME_LINK_SOUTH_AMERICA:
    process.env.NEXT_PUBLIC_GAME_LINK_SOUTH_AMERICA,
  NEXT_PUBLIC_GAME_LINK_SOUTH_EAST_ASIA:
    process.env.NEXT_PUBLIC_GAME_LINK_SOUTH_EAST_ASIA,
  NEXT_PUBLIC_GAME_LINK_EUROPE: process.env.NEXT_PUBLIC_GAME_LINK_EUROPE,

  NEXT_PUBLIC_SALE_API: process.env.NEXT_PUBLIC_SALE_API,

  NEXT_PUBLIC_POLYGON_LAND_CONTRACT_ID:
    process.env.NEXT_PUBLIC_POLYGON_LAND_CONTRACT_ID,
  NEXT_PUBLIC_POLYGON_BADGE_CONTRACT_ID:
    process.env.NEXT_PUBLIC_POLYGON_BADGE_CONTRACT_ID,

  NEXT_PUBLIC_IMX_LAND_CONTRACT_ID:
    process.env.NEXT_PUBLIC_IMX_LAND_CONTRACT_ID,
  NEXT_PUBLIC_IMX_BADGE_CONTRACT_ID:
    process.env.NEXT_PUBLIC_IMX_BADGE_CONTRACT_ID,
  NEXT_PUBLIC_IMX_API_URL: process.env.NEXT_PUBLIC_IMX_API_URL,
  NEXT_PUBLIC_IMX_LINK_URL: process.env.NEXT_PUBLIC_IMX_LINK_URL,
  NEXT_PUBLIC_IMX_MIGRATION_API: process.env.NEXT_PUBLIC_IMX_MIGRATION_API,
  NEXT_PUBLIC_IMX_MARKETPLACE_URL: process.env.NEXT_PUBLIC_IMX_MARKETPLACE_URL,
  NEXT_PUBLIC_USDC_ADDRESS: process.env.NEXT_PUBLIC_USDC_ADDRESS,
  NEXT_PUBLIC_SALE_DISCOUNT: process.env.NEXT_PUBLIC_SALE_DISCOUNT,
  NEXT_PUBLIC_FRONTEND_CART_EXPIRATION_MS:
    process.env.NEXT_PUBLIC_FRONTEND_CART_EXPIRATION_MS,
  NEXT_PUBLIC_TOKEN_RECEIVER_ADDRESS:
    process.env.NEXT_PUBLIC_TOKEN_RECEIVER_ADDRESS,
  NEXT_PUBLIC_IMX_BURN_BATCH_AMOUNT:
    process.env.NEXT_PUBLIC_IMX_BURN_BATCH_AMOUNT,
  NEXT_PUBLIC_IMX_BURN_BADGE_AMOUNT:
    process.env.NEXT_PUBLIC_IMX_BURN_BADGE_AMOUNT,
  NEXT_PUBLIC_THIRD_WEB_CLIENT_ID: process.env.NEXT_PUBLIC_THIRD_WEB_CLIENT_ID,
  NEXT_PUBLIC_GLOBAL_SETTINGS: process.env.NEXT_PUBLIC_GLOBAL_SETTINGS,
});

// This configuration is exposed to the client. All the environment variables must be prefixed with NEXT_PUBLIC_
export const clientConfig = {
  environment: clientEnvironment.NEXT_PUBLIC_ENVIRONMENT,

  esAuthApi: clientEnvironment.NEXT_PUBLIC_ES_AUTH_API,
  gameLink: clientEnvironment.NEXT_PUBLIC_GAME_LINK,

  gameLinkNorthAmerica: clientEnvironment.NEXT_PUBLIC_GAME_LINK_NORTH_AMERICA,
  gameLinkSouthAmerica: clientEnvironment.NEXT_PUBLIC_GAME_LINK_SOUTH_AMERICA,
  gameLinkSouthEastAsia:
    clientEnvironment.NEXT_PUBLIC_GAME_LINK_SOUTH_EAST_ASIA,
  gameLinkEurope: clientEnvironment.NEXT_PUBLIC_GAME_LINK_EUROPE,

  saleApi: clientEnvironment.NEXT_PUBLIC_SALE_API,

  polygonLandContractId: clientEnvironment.NEXT_PUBLIC_POLYGON_LAND_CONTRACT_ID,
  polygonBadgeContractId:
    clientEnvironment.NEXT_PUBLIC_POLYGON_BADGE_CONTRACT_ID,

  imxLandContractId: clientEnvironment.NEXT_PUBLIC_IMX_LAND_CONTRACT_ID,
  imxBadgeContractId: clientEnvironment.NEXT_PUBLIC_IMX_BADGE_CONTRACT_ID,
  imxApiUrl: clientEnvironment.NEXT_PUBLIC_IMX_API_URL,
  imxLinkUrl: clientEnvironment.NEXT_PUBLIC_IMX_LINK_URL,
  imxMigrationApi: clientEnvironment.NEXT_PUBLIC_IMX_MIGRATION_API,
  imxMarketplaceUrl: clientEnvironment.NEXT_PUBLIC_IMX_MARKETPLACE_URL,
  imxBurnBatchAmount: clientEnvironment.NEXT_PUBLIC_IMX_BURN_BATCH_AMOUNT,
  imxBurnBadgeAmount: clientEnvironment.NEXT_PUBLIC_IMX_BURN_BADGE_AMOUNT,
  imxUsdcTokenAddress: clientEnvironment.NEXT_PUBLIC_USDC_ADDRESS,
  imxLandSaleReceiverAddress:
    clientEnvironment.NEXT_PUBLIC_TOKEN_RECEIVER_ADDRESS,
  saleDiscount: clientEnvironment.NEXT_PUBLIC_SALE_DISCOUNT,
  frontendCartExpirationMs:
    clientEnvironment.NEXT_PUBLIC_FRONTEND_CART_EXPIRATION_MS,
  thirdWebClientId: clientEnvironment.NEXT_PUBLIC_THIRD_WEB_CLIENT_ID,
  globalSettingsUrl: clientEnvironment.NEXT_PUBLIC_GLOBAL_SETTINGS,
};
