import { twMerge } from "tailwind-merge";
import { motion } from "framer-motion";
import SvgArrow from "assets/svgs/arrow.svg";
import { Fragment } from "react";
import { ButtonCore } from "@brightstarstudios/components";
import { Disclosure } from "@headlessui/react";

export interface AccordionProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "title"> {
  /** Determins if the content of the accordion is visible */
  active?: boolean;
  /** Title for the accordion */
  title: React.ReactNode;
  /** Fires when the accordion title is clicked */
  onChange?: () => void;
  hideCaret?: boolean;
}

/**
 * This is an accordion that fires a callback (onChange) when clicked. The parent should handle the state.
 *
 * @example
 * <Accordion active={open} onChange={() => setActive(!open)}>
 *  <p>Some content</p>
 * </Accordion>
 */
export const Accordion: React.FC<AccordionProps> = ({
  active = false,
  children,
  title = "Accordion title",
  onChange,
  hideCaret,
  className = "",
  ...rest
}) => {
  const rotateIconClass = active ? "rotate-90" : "rotate-0";

  const animation = {
    height: active ? "auto" : 0,
  };

  return (
    <Disclosure>
      <div
        className={twMerge(
          "max-w-full rounded-xl bg-white text-tertiary-600",
          className,
        )}
        {...rest}
      >
        <Disclosure.Button as={Fragment}>
          <ButtonCore
            onClick={onChange}
            className="relative flex w-full items-center justify-between rounded-xl p-4"
          >
            <span className="block w-full pr-2 text-left">{title}</span>

            {!hideCaret && (
              <SvgArrow
                className={twMerge("h-4 w-4 transition-all", rotateIconClass)}
              />
            )}
          </ButtonCore>
        </Disclosure.Button>
        <motion.div
          className="overflow-hidden rounded-b-xl px-6"
          initial={{ height: 0 }}
          animate={animation}
          transition={{ duration: 0.1 }}
        >
          <Disclosure.Panel static>
            <div className="pb-6">{children}</div>
          </Disclosure.Panel>
        </motion.div>
      </div>
    </Disclosure>
  );
};
