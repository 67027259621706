/** Function to calcutate if badges value (in USD) are below or above certain threshold */
const THRESHOLD = 1000;

export const badgesExceedUsdcThreshold = (badgeIds: string[]) => {
  const total = badgeIds.reduce((value, badgeId) => {
    const id = parseInt(badgeId);

    // Bronze badge
    if (id < 100000) {
      value += 250;
    }
    // Silver badge
    else if (id < 200000) {
      value += 500;
    }
    // Gold badge
    else if (id < 300000) {
      value += 2500;
    }
    // Platinum badge
    else if (id < 500000) {
      value += 25000;
    }

    return value;
  }, 0);

  return total >= THRESHOLD;
};
