import { getUser } from "api/account";
import { QUERY_KEYS } from "api/queryKeys";
import { useQuery } from "@tanstack/react-query";
import { useAuthCookie } from "./useAuthCookie";

export const useUser = () => {
  const { authToken } = useAuthCookie();

  const { data: user, ...rest } = useQuery({
    queryKey: [QUERY_KEYS.USER, authToken],
    queryFn: () => {
      if (!authToken) {
        return null;
      }

      return getUser(authToken);
    },
  });

  return {
    user,
    ...rest,
  };
};
