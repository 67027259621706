import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "api/queryKeys";
import { ERC20TokenType, Link } from "@imtbl/imx-sdk";
import { clientConfig } from "config/client";
import { checkForRegisteredImxUser } from "api/imx";

export const link = new Link(clientConfig.imxLinkUrl, null);

export const useImx = () => {
  const queryClient = useQueryClient();

  const { data: account } = useQuery<string | null>({
    queryKey: [QUERY_KEYS.IMX_ACCOUNT],
    queryFn: () => null,
  });
  const { data: usdcBalance } = useQuery<number | null>({
    queryKey: [QUERY_KEYS.IMX_USER_USDC_BALANCE],
    queryFn: () => null,
  });

  // Setup and link wallet to IMX
  const linkAccount = useMutation({
    mutationFn: async () => {
      const { address } = await link.setup({});
      return address;
    },
    onSuccess: (account) =>
      queryClient.setQueryData([QUERY_KEYS.IMX_ACCOUNT], account),
  });

  // Deposit funds from mainnet
  const { mutate: depositUsdc } = useMutation({
    mutationFn: async () => {
      return await link.deposit({
        type: ERC20TokenType.ERC20,
        tokenAddress: clientConfig.imxUsdcTokenAddress,
        symbol: "USDC",
      });
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.IMX_USER_USDC_BALANCE],
      }),
  });
  // Deposit funds via credit card payment
  const { mutate: depositUsdcCreditCard } = useMutation({
    mutationFn: () => link.fiatToCrypto({ cryptoCurrencies: ["USDC"] }),
  });

  const registeredOnImx = useMutation({
    mutationFn: async (account: string) => {
      const { accounts } = await checkForRegisteredImxUser(account);

      return accounts.length > 0;
    },
    onSuccess: () => {
      queryClient.setQueryData([QUERY_KEYS.IMX_ACCOUNT], account);
    },
  });

  return {
    linkAccount,
    depositUsdc,
    depositUsdcCreditCard,
    account,
    usdcBalance,
    registeredOnImx,
  };
};
