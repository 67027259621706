import { showToast } from "components/Toast";
import type { ToastOptions } from "react-toastify";
import { IS_DEV_ENVIRONMENT } from "./isDevEnvironment";
import { getStringErrorMessage } from "@brightstarstudios/utilities";

export const displayError = (error?: unknown) => {
  const options: ToastOptions = IS_DEV_ENVIRONMENT
    ? {
        draggable: false,
        autoClose: false,
      }
    : {};

  const errorMessage = getStringErrorMessage(error);

  // Add toast id to avoid multiple toasts with same message
  showToast.error(errorMessage, { ...options, toastId: errorMessage });
};
