import { getHashParamFromUrl } from "@brightstarstudios/utilities";
import { useRouter } from "next/router";
import { useEffect } from "react";

export const useMobileMenu = () => {
  const { asPath, push } = useRouter();

  const open = Boolean(getHashParamFromUrl("mobile-menu-open", asPath));

  useEffect(() => {
    // prevent overflow scroll on body when mobile menu is open
    if (open) {
      document.querySelector("body")?.classList.add("overflow-hidden");
    } else {
      document.querySelector("body")?.classList.remove("overflow-hidden");
    }
  }, [open]);

  const toggle = () => {
    push({ hash: open ? undefined : "mobile-menu-open=true" }, undefined, {
      shallow: true,
      scroll: false,
    });
  };

  return {
    open,
    toggle,
  };
};
