import React from "react";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "api/queryKeys";
import { ModalVideoContentSchema } from "types/modals";
import { z } from "zod";
import { useRouter } from "next/router";
import { useModal } from "hooks/useModal";
import {
  getHashParamFromUrl,
  youTubeUrlParser,
} from "@brightstarstudios/utilities";

// Some youtube urls is linked from the web site but does not exist in the cms.
// These are hardcoded here for now. If you deleted this make sure that the modal can still open these videos.
const HARDCODED_APPROVED_YOUTUBE_IDS: string[] = [
  "jPcbovVosmY",
  "4Rb4ory20rw",
  "J-Wb2xmJjaI",
  "d2x8iTW2Xms",
  "2iMqELuKt_Q",
  "nfkP8vo2CSI",
  "HGK-QMYk_Ak",
  "MEjShBybjLI",
  "XFnzYxYVgeM",
  "R04uh9Khtqc",
  "h_PTVRU5b7o",
  "-dvogjPbAfs",
  "iAPYK1fGSUA",
  "Mh7n93MLUZ8",
  "lrwuNhAWuPU",
  "db5CtT_khNw",
];

export const ModalVideo: React.FC = () => {
  // We fetch the approved youtube ids from json file in /public/cms/approvedYoutubes.json which is populated at build time.
  const { data: approvedVideos } = useQuery({
    queryKey: [QUERY_KEYS.APPROVED_YOUTUBE_IDS],
    queryFn: async () => {
      const data = await fetch("/cms/approvedYoutubes.json");
      return z.array(z.string()).parse(await data.json());
    },
  });

  const router = useRouter();
  const { hideModal } = useModal();

  const modalContentHashParam = getHashParamFromUrl(
    "modal-content",
    router.asPath,
  );

  // Check if we have a modal content in the URL hash params. If not, redirect to home page.
  if (!modalContentHashParam) {
    hideModal();
    return null;
  }

  const modalContentHashParamValue = JSON.parse(
    decodeURIComponent(modalContentHashParam),
  );
  const contentParsed = ModalVideoContentSchema.safeParse(
    modalContentHashParamValue,
  );

  // Modal content is invalid. Redirect to home page.
  if (!contentParsed.success) {
    hideModal();
    return null;
  }

  const { src, ratio } = contentParsed.data;

  const [w, h] = ratio.split(":");

  const iframeSize: React.CSSProperties = {
    paddingTop: `${(Number(h) / Number(w)) * 100}%`,
  };

  const id = youTubeUrlParser({ url: src });

  // We check if the parsed id is in the approved list of youtube videos.
  const approved =
    id &&
    approvedVideos &&
    (approvedVideos.includes(id) ||
      HARDCODED_APPROVED_YOUTUBE_IDS.includes(id));

  return (
    <div>
      {approved && (
        <>
          <div style={iframeSize} />
          <iframe
            className="absolute inset-0 h-full w-full"
            src={`https://www.youtube.com/embed/${id}?controls=1&autoplay=1&cc_load_policy=1`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </>
      )}
    </div>
  );
};
