import React from "react";
import { Modal } from "../Modal";
import { ModalNewsletterInterestContentSchema } from "types/modals";
import { Prose } from "components/Prose";
import { useRouter } from "next/router";
import { useModal } from "hooks/useModal";
import { getHashParamFromUrl } from "@brightstarstudios/utilities";

const TEXTS = {
  team: "The next email you'll receive will tell you about our founders.",
  lore: "The next emails you'll receive will be about the lore of Ember Sword.",
  gameplay:
    "The next email you'll receive will be about the gameplay of Ember Sword.",
} as const;

export const ModalNewsletterInterest: React.FC = () => {
  const router = useRouter();
  const { hideModal } = useModal();

  const modalContentHashParam = getHashParamFromUrl(
    "modal-content",
    router.asPath,
  );

  // Check if we have a modal content in the URL hash params. If not, redirect to home page.
  if (!modalContentHashParam) {
    hideModal();
    return null;
  }

  const modalContentHashParamValue = JSON.parse(
    decodeURIComponent(modalContentHashParam),
  );
  const contentParsed = ModalNewsletterInterestContentSchema.safeParse(
    modalContentHashParamValue,
  );

  // Modal content is invalid. Redirect to home page.
  if (!contentParsed.success) {
    hideModal();
    return null;
  }

  const { interest } = contentParsed.data;

  return (
    <Modal.Info>
      <Prose className="text-center">
        <h3>
          <strong>Thanks!</strong> {TEXTS[interest]}
        </h3>
        <p>You can now go back to the email you were reading!</p>
      </Prose>
    </Modal.Info>
  );
};
