import React, { useEffect, useState } from "react";
import { Modal } from "../Modal";
import { Prose } from "components/Prose";
import { useModal } from "hooks/useModal";
import { ButtonAnimated } from "@brightstarstudios/components";
import ConfettiExplosion from "react-confetti-explosion";

export const ModalBadgeBurnedSuccess: React.FC = () => {
  const { hideModal } = useModal();
  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    setShowConfetti(true);
  }, [showConfetti]);

  return (
    <>
      <div className="relative z-40">
        <Modal.Info>
          <Prose className="text-center">
            <h2>Congratulations!</h2>

            <p>
              You have now started the process for redeeming your $EMBER tokens.
              You will receive a confirmation email from the Ember Sword Team
              via our rashi@embersword.com email address shortly. This will be
              sent to the email address used for your Ember Sword account and
              will contain all the necessary information including instructions
              on the next steps.
            </p>

            <p>We appreciate your ongoing support.</p>

            <p>Ember Sword</p>
          </Prose>

          <div className="flex justify-center">
            <ButtonAnimated
              color="red"
              onClick={hideModal}
              label="Close"
              className="w-56"
            />
          </div>
        </Modal.Info>
      </div>

      {showConfetti && (
        <ConfettiExplosion
          zIndex={50}
          className="pointer-events-none fixed inset-0 hidden translate-x-1/2 translate-y-1/3 cursor-none motion-safe:block"
          onComplete={() => setShowConfetti(true)}
        />
      )}
    </>
  );
};
