import { createAccount } from "api/account";
import { Form, Formik } from "formik";
import { displayError } from "utils/displayError";
import { useModal } from "hooks/useModal";
import { Input } from "components/form-elements/Input";
import { Checkbox } from "components/form-elements/Checkbox";
import { AnimatedHeight } from "components/AnimatedHeight";
import { RecaptchaV2 } from "components/RecaptchaV2";
import { ButtonAnimated } from "@brightstarstudios/components";
import { EmailSchema, PasswordSchema } from "schemas/account-api";
import Link from "next/link";
import { TextPoppins } from "@brightstarstudios/components";
import { useState } from "react";
import { FormErrors } from "components/form-elements/FormErrors";

const INITIAL_VALUES = {
  email: "",
  password: "",
  passwordRepeated: "",
  consent: false,
  recaptcha: null,
} as const;

export const FormCreateAccount = () => {
  const { showModal } = useModal();
  const [showRecaptcha, setShowRecaptcha] = useState(false);

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      validate={(values) => {
        const errors: Record<string, string> = {};

        const emailParse = EmailSchema.safeParse(values.email);
        if (!emailParse.success) {
          errors.email = emailParse.error.issues
            .map((issue) => issue.message)
            .join("\n");
        }

        const passwordParse = PasswordSchema.safeParse(values.password);
        if (!passwordParse.success) {
          errors.password = passwordParse.error.issues
            .map((issue) => issue.message)
            .join("\n");
        }

        if (values.password !== values.passwordRepeated) {
          errors.passwordRepeated = "Passwords do not match";
        }

        if (!values.consent) {
          errors.consent = "Please accept the terms and conditions";
        }

        return errors;
      }}
      onSubmit={async (values, { setSubmitting, setFieldError }) => {
        if (!values.recaptcha) {
          setFieldError("recaptcha", "Please verify you are not a robot");
          return;
        }

        try {
          await createAccount({
            email: values.email,
            password: values.password,
            passwordRepeated: values.passwordRepeated,
            recaptcha: values.recaptcha,
          });

          showModal("create-account-info");
        } catch (error) {
          displayError(error);
        }

        setSubmitting(false);
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        isValid,
        isSubmitting,
        setFieldValue,
        errors,
        dirty,
        touched,
      }) => {
        const loadRecaptcha = isValid && dirty;

        return (
          <Form className="flex flex-col">
            <FormErrors errors={errors} touched={touched} />
            <Input
              color="light-gray"
              className="mb-6"
              type="email"
              name="email"
              placeholder="E-mail"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <Input
              color="light-gray"
              className="mb-6"
              type="password"
              name="password"
              placeholder="Password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              color="light-gray"
              type="password"
              name="passwordRepeated"
              placeholder="Confirm password"
              value={values.passwordRepeated}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <label className="flex cursor-pointer items-center">
              <Checkbox
                color="GRAY"
                className="mr-4"
                name="consent"
                onChange={handleChange}
                onBlur={handleBlur}
                checked={values.consent}
              />

              <TextPoppins size="medium" className="my-6 text-tertiary-600">
                I agree to receive offers, news, and updates about ember sword
                and accept the{" "}
                <Link
                  className="text-primary-red"
                  href="/terms-and-conditions"
                  target="_blank"
                >
                  terms & conditions
                </Link>{" "}
                &{" "}
                <Link
                  className="text-primary-red"
                  href="/privacy-policy"
                  target="_blank"
                >
                  privacy policy.
                </Link>
              </TextPoppins>
            </label>

            <AnimatedHeight
              show={showRecaptcha}
              className="mb-4 flex justify-center overflow-hidden"
            >
              {loadRecaptcha && (
                <RecaptchaV2
                  className="mt-4"
                  asyncScriptOnLoad={() => setShowRecaptcha(true)}
                  theme="light"
                  onChange={(token) => {
                    setFieldValue("recaptcha", token);
                  }}
                  onExpired={() => {
                    setFieldValue("recaptcha", null);
                  }}
                />
              )}
            </AnimatedHeight>

            <div className="flex justify-center">
              <ButtonAnimated
                color="red"
                type="submit"
                disabled={
                  isSubmitting || !dirty || !isValid || !values.recaptcha
                }
                className="my-4"
                label={isSubmitting ? "Waiting..." : "Create account"}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
