import {
  ERC20TokenType,
  ImmutableMethodParams,
  ImmutableXClient,
} from "@imtbl/imx-sdk";
import { clientConfig } from "config/client";
import { ethers } from "ethers";
import { link } from "hooks/useImx";
import {
  ImxUserResponseFailureSchema,
  ImxUserResponseSuccessSchema,
  ImxAssetsResponseSchema,
} from "schemas/imx";

export const getImxAssets = async (
  options: ImmutableMethodParams.ImmutableGetAssetsParamsTS,
) => {
  const client = await ImmutableXClient.build({
    publicApiUrl: `${clientConfig.imxApiUrl}/v1`,
  });

  const response = await client.getAssets(options);

  const data = ImxAssetsResponseSchema.safeParse(response);

  if (!data.success) {
    console.log("schema error", data.error);
    // The response from the auth api is 200, but not the shape we expect
    throw new Error("Unexpected data from api", { cause: data.error });
  }

  return data.data;
};

export const checkForRegisteredImxUser = async (account: string) => {
  const url = `${clientConfig.imxApiUrl}/v1/users/${account}`;

  const res = await fetch(url);

  if (!res.ok) {
    const failureBody = ImxUserResponseFailureSchema.safeParse(
      await res.json(),
    );

    if (!failureBody.success) {
      throw new Error("Unknown error", { cause: failureBody.error });
    }

    if (failureBody.data.code === "account_not_found") {
      return { accounts: [] };
    }

    throw new Error("Unknown error", { cause: failureBody });
  }

  const successBody = ImxUserResponseSuccessSchema.safeParse(await res.json());
  if (!successBody.success) {
    // The response from the auth api is 200, but not the shape we expect
    throw new Error("Unexpected data from api", { cause: successBody.error });
  }

  return successBody.data;
};

export const checkForRegisteredSandboxImxUser = async (account: string) => {
  const url = `https://api.sandbox.x.immutable.com/v1/users/${account}`;

  const res = await fetch(url);

  if (!res.ok) {
    const failureBody = ImxUserResponseFailureSchema.safeParse(
      await res.json(),
    );

    if (!failureBody.success) {
      throw new Error("Unknown error", { cause: failureBody.error });
    }

    if (failureBody.data.code === "account_not_found") {
      return { accounts: [] };
    }

    throw new Error("Unknown error", { cause: failureBody });
  }

  const successBody = ImxUserResponseSuccessSchema.safeParse(await res.json());
  if (!successBody.success) {
    // The response from the auth api is 200, but not the shape we expect
    throw new Error("Unexpected data from api", { cause: successBody.error });
  }

  return successBody.data;
};

export const checkUserUSDCBalance = async (account: string) => {
  const client = await ImmutableXClient.build({
    publicApiUrl: `${clientConfig.imxApiUrl}/v2`,
  });

  const { balance } = await client.getBalance({
    user: account,
    tokenAddress: clientConfig.imxUsdcTokenAddress,
  });

  return Number(ethers.utils.formatUnits(balance, 6));
};

export const transferUsdcForLandsale = async (amount: number) => {
  const { result } = await link.transfer([
    {
      type: ERC20TokenType.ERC20,
      toAddress: clientConfig.imxLandSaleReceiverAddress,
      amount: amount.toString(),
      tokenAddress: clientConfig.imxUsdcTokenAddress,
      symbol: "USDC",
    },
  ]);
  const interestingResult = result[0]; // We only care about the first result, since there is only one transfer above ^^
  if (interestingResult.type !== ERC20TokenType.ERC20)
    throw new Error("Token type mismatch!"); // This should never happen so it's just to satisfy TS
  if (interestingResult.status !== "success")
    throw new Error(interestingResult.message);

  return interestingResult;
};

export const getImxAssetsSandbox = async (
  options: ImmutableMethodParams.ImmutableGetAssetsParamsTS,
) => {
  const client = await ImmutableXClient.build({
    publicApiUrl: `https://api.sandbox.x.immutable.com/v1`,
  });

  return await client.getAssets(options);
};
