import React from "react";
import { Modal } from "../Modal";
import { Prose } from "components/Prose";
import { useFormik } from "formik";
import { Input } from "components/form-elements/Input";
import { ButtonAnimated } from "@brightstarstudios/components";
import { displayError } from "utils/displayError";
import { ERC721TokenType } from "@imtbl/imx-sdk";
import { link } from "hooks/useImx";
import { WalletAddressSchema } from "schemas/imx";
import { withZodSchema } from "formik-validator-zod";
import { z } from "zod";
import { useRouter } from "next/router";
import { ModalTransferImxBatchNftSchema } from "types/modals";
import { useModal } from "hooks/useModal";
import { useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "api/queryKeys";
import { delay, getHashParamFromUrl } from "@brightstarstudios/utilities";

export const ModalAssetTransfer: React.FC = () => {
  const router = useRouter();
  const { hideModal } = useModal();
  const queryClient = useQueryClient();

  const { handleChange, dirty, isSubmitting, values, handleSubmit, errors } =
    useFormik({
      initialValues: {
        toAddress: "",
      },
      validate: withZodSchema(z.object({ toAddress: WalletAddressSchema })),
      validateOnChange: false,
      onSubmit: async (values, { setSubmitting }) => {
        try {
          const modalContentHashParam = getHashParamFromUrl(
            "modal-content",
            router.asPath,
          );

          // Check if we have a modal content in the URL hash params. If not, redirect to home page.
          if (!modalContentHashParam) {
            throw "Invalid asset information";
          }

          const modalContentHashParamValue = JSON.parse(
            decodeURIComponent(modalContentHashParam),
          );

          const contentParsed = ModalTransferImxBatchNftSchema.safeParse(
            modalContentHashParamValue,
          );

          // Modal content is invalid. Redirect to home page.
          if (!contentParsed.success) {
            throw "Invalid asset information. Missing asset id or asset address";
          }
          const assets = contentParsed.data.map((asset) => ({
            ...asset,
            type: ERC721TokenType.ERC721,
            toAddress: values.toAddress,
          }));

          await link.batchNftTransfer(assets);

          hideModal();

          // Delay since refetch of assets can show old result if fetched right away
          await delay(1500);

          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.IMX_OWNER_BADGES],
          });
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.IMX_OWNER_LAND],
          });
        } catch (error) {
          displayError(error);
        } finally {
          setSubmitting(false);
        }
      },
    });

  if (errors.toAddress) {
    displayError(errors.toAddress);
  }

  return (
    <Modal.Info>
      <Prose className="text-center">
        <h2>Transfer asset(s)</h2>
        <p>
          Enter the recipient&apos;s wallet address to transfer your asset(s).
        </p>
        <p>
          Please double-check the recipient&apos;s address to ensure it is
          correct. Once confirmed, the transfer will be irreversible.
        </p>

        <form className="flex w-full flex-col" onSubmit={handleSubmit}>
          <Input
            color="light-gray"
            className="mb-6"
            name="toAddress"
            placeholder="Recipient"
            value={values.toAddress}
            onChange={handleChange}
          />

          <ButtonAnimated
            color="red"
            disabled={isSubmitting || !dirty}
            className="w-56 self-center"
            label={isSubmitting ? "Waiting..." : "Transfer"}
            type="submit"
          />
        </form>
      </Prose>
    </Modal.Info>
  );
};
