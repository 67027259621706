import { resetPassword } from "api/account";
import { Form, Formik } from "formik";
import React from "react";
import { Input } from "components/form-elements/Input";
import { ButtonAnimated } from "@brightstarstudios/components";
import { showToast } from "components/Toast";
import { useModal } from "hooks/useModal";
import { displayError } from "utils/displayError";
import { useRouter } from "next/router";
import { PasswordSchema } from "schemas/account-api";
import { FormErrors } from "components/form-elements/FormErrors";

const INITIAL_VALUES = {
  password: "",
  passwordRepeated: "",
} as const;

export const FormResetPassword: React.FC = () => {
  const { hideModal } = useModal();

  const router = useRouter();
  const { code } = router.query;

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      validate={(values) => {
        const passwordParse = PasswordSchema.safeParse(values.password);
        if (!passwordParse.success) {
          return {
            password: passwordParse.error.issues
              .map((issue) => issue.message)
              .join("\n"),
          };
        }

        if (values.password !== values.passwordRepeated) {
          return { passwordRepeated: "Passwords do not match" };
        }
      }}
      onSubmit={async (values) => {
        try {
          // No ?code= in url
          if (!code) {
            throw new Error(
              "Unable to reset password. No reset code provided.",
            );
          }
          // Multiple ?code= in url
          if (Array.isArray(code)) {
            throw new Error(
              "Unable to reset password. Multiple reset codes provided.",
            );
          }

          await resetPassword({ ...values, code });
          showToast.info("Password has been changed");
          hideModal();
        } catch (error) {
          displayError(error);
        }
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        isValid,
        isSubmitting,
        dirty,
        errors,
        touched,
      }) => {
        return (
          <Form className="flex flex-col">
            <FormErrors errors={errors} touched={touched} />
            <Input
              color="light-gray"
              className="mb-6"
              type="password"
              name="password"
              placeholder="Password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              color="light-gray"
              className="mb-6"
              type="password"
              name="passwordRepeated"
              placeholder="Password again"
              value={values.passwordRepeated}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <div className="flex justify-center">
              <ButtonAnimated
                type="submit"
                color="red"
                disabled={!isValid || isSubmitting || !dirty}
                className="my-4"
                label="Reset"
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
