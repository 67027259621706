import { twMerge } from "tailwind-merge";
import React from "react";

interface MenuItemProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  active?: boolean;
  children?: React.ReactNode;
}

export const MenuItem: React.FC<MenuItemProps> = ({
  children,
  className = "",
  active,
  ...rest
}) => {
  const activeClass = active ? "text-primary-red" : "text-tertiary-600";

  return (
    <button
      className={twMerge(
        "flex w-full items-center gap-x-2 whitespace-nowrap p-3 text-left font-poppins font-medium leading-relaxed tracking-wide transition-all duration-300 hover:text-primary-red disabled:cursor-not-allowed disabled:opacity-40",
        className,
        activeClass,
      )}
      {...rest}
    >
      {children}
    </button>
  );
};
