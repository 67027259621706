import React from "react";
import ReCAPTCHA, { ReCAPTCHAProps } from "react-google-recaptcha";

type RecaptchaV2Props = Omit<ReCAPTCHAProps, "sitekey">;

export const RecaptchaV2: React.FC<RecaptchaV2Props> = ({
  theme = "dark",
  ...rest
}) => {
  return (
    <ReCAPTCHA
      theme={theme}
      sitekey="6LdqdQgfAAAAAANSRFYH8iLtn4nK3HpKqM1SMyBp"
      {...rest}
    />
  );
};
