import { TitleJost } from "@brightstarstudios/components";
import SvgEmbersword from "assets/svgs/icons/embersword.svg";
import { FormNewsletterSignup } from "components/forms/FormNewsletterSignup";

export const ModalNewsletterSignup = () => {
  return (
    <div className="w-72 bg-white md:w-96">
      <div className="flex flex-col items-center justify-center bg-primary-red p-8 text-white">
        <SvgEmbersword width={75} height={125} />
        <TitleJost
          size="medium"
          variant="h1"
          className="mt-6 text-center font-extrabold uppercase"
        >
          Sign-up to our newsletter
        </TitleJost>
      </div>
      <div className="px-4 py-8 lg:p-8">
        <FormNewsletterSignup
          inputColor="light-gray"
          checkboxColor="black"
          buttonAnimationColor="red"
        />
      </div>
    </div>
  );
};
