import React, { useEffect, useState } from "react";
import { Modal } from "../Modal";
import { useModal } from "hooks/useModal";
import { useRouter } from "next/router";
import { useMutation } from "@tanstack/react-query";
import { activateAccount } from "api/account";
import { TextPoppins } from "@brightstarstudios/components";

export const ModalAccountActivaction: React.FC = () => {
  const router = useRouter();
  const { code: activationCode } = router.query;
  const { hideModal } = useModal();

  const [activationImpossibleError, setActivationImpossibleError] =
    useState<string>();

  const { mutate, isPending, isSuccess, isError, error } = useMutation({
    mutationFn: activateAccount,
    onSuccess: () => {
      setTimeout(() => {
        hideModal();
      }, 5000);
    },
    onError: (error: Error) => {
      return error;
    },
  });

  useEffect(() => {
    // No ?code= in url
    if (!activationCode) {
      setActivationImpossibleError("No activation code provided");
      return;
    }
    // Multiple ?code= in url
    if (Array.isArray(activationCode)) {
      setActivationImpossibleError("Multiple activation codes provided");
      return;
    }

    // Activate account
    mutate({ activationCode });
  }, [activationCode, mutate]);

  return (
    <Modal.Info>
      <TextPoppins size="medium" className="text-center text-tertiary-600">
        {activationImpossibleError ? (
          activationImpossibleError
        ) : (
          <>
            {isPending && "Activating account..."}
            {isError && error.message}
            {isSuccess && (
              <>
                <strong>Thank you!</strong> Your account has been succesfully
                activated
              </>
            )}
          </>
        )}
      </TextPoppins>
    </Modal.Info>
  );
};
