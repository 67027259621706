import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import SvgAvatar from "assets/svgs/icons/avatar.svg";
import SvgWriting from "assets/svgs/icons/writing.svg";
import SvgCrown from "assets/svgs/icons/crown.svg";
import SvgEnvelope from "assets/svgs/icons/envelope.svg";
import SvgLock from "assets/svgs/icons/lock.svg";
import SvgStar from "assets/svgs/icons/star.svg";
import { useAllowedToPlay } from "hooks/useAllowedToPlay";
import { clientConfig } from "config/client";
import Link from "next/link";
import SvgPlay from "assets/svgs/icons/play.svg";
import { useLogout } from "hooks/useLogout";
import { MenuItem } from "./MenuItem";
import { MenuItemDivider } from "./MenuItemDivider";
import { useModal } from "hooks/useModal";

export const MenuAccount = () => {
  const { data: allowPlay } = useAllowedToPlay();
  const { logout } = useLogout();
  const { showModal } = useModal();

  const canPlay =
    allowPlay && clientConfig.gameLink && clientConfig.gameLinkSouthEastAsia;

  const onRegionPick = (gameLink: string) => {
    // if (!gameRequirementsMet) {
    //   displayRequirementsNotMetToast();
    // } else {
    showModal("beta-disclaimer", gameLink);
    // }
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-tertiary-600 bg-white text-tertiary-600 transition-colors duration-300 hover:bg-tertiary-600 hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-red">
        <SvgAvatar className="h-5 w-5" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2 origin-top-right overflow-hidden rounded-xl bg-white shadow-xl">
          <Menu.Item>
            {({ active }) => (
              <Link href="/account">
                <MenuItem active={active}>
                  <SvgWriting className="h-4 w-4" aria-hidden="true" />
                  Personal information
                </MenuItem>
              </Link>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <Link href="/account/imx-migration">
                <MenuItem active={active}>
                  <SvgStar className="h-4 w-4" aria-hidden="true" />
                  Migrate to IMX
                </MenuItem>
              </Link>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <Link href="/account/assets">
                <MenuItem active={active}>
                  <SvgCrown className="h-4 w-4" aria-hidden="true" />
                  Assets
                </MenuItem>
              </Link>
            )}
          </Menu.Item>

          {!canPlay && (
            <Menu.Item>
              {({ active }) => (
                <Link href="/account/promo">
                  <MenuItem active={active}>
                    <SvgStar className="h-4 w-4" aria-hidden="true" />
                    Promo code
                  </MenuItem>
                </Link>
              )}
            </Menu.Item>
          )}

          <Menu.Item>
            {({ active }) => (
              <Link href="/account/support">
                <MenuItem active={active}>
                  <SvgEnvelope className="h-4 w-4" aria-hidden="true" />
                  Contact
                </MenuItem>
              </Link>
            )}
          </Menu.Item>

          {canPlay && (
            <>
              <MenuItemDivider />
              <Menu.Item>
                {({ active }) => (
                  <MenuItem
                    active={active}
                    onClick={() =>
                      onRegionPick(clientConfig.gameLinkSouthEastAsia)
                    }
                  >
                    <SvgPlay className="h-4 w-4" aria-hidden="true" />
                    {/* Play: Region Europe */}
                    Play
                  </MenuItem>
                )}
              </Menu.Item>
              {/* <Menu.Item>
                {({ active }) => (
                  <MenuItem
                    active={active}
                    onClick={() => {
                      onRegionPick(clientConfig.gameLinkNorthAmerica);
                    }}
                  >
                    <SvgPlay className="w-4 h-4" aria-hidden="true" />
                    Play: North America
                  </MenuItem>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <MenuItem
                    active={active}
                    onClick={() => {
                      onRegionPick(clientConfig.gameLinkSouthAmerica);
                    }}
                  >
                    <SvgPlay className="w-4 h-4" aria-hidden="true" />
                    Play: South America
                  </MenuItem>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <MenuItem
                    active={active}
                    onClick={() =>
                      onRegionPick(clientConfig.gameLinkSouthEastAsia)
                    }
                  >
                    <SvgPlay className="w-4 h-4" aria-hidden="true" />
                    Play: Region South East Asia
                  </MenuItem>
                )}
              </Menu.Item> */}
            </>
          )}

          <MenuItemDivider />

          <Menu.Item>
            {({ active }) => (
              <MenuItem active={active} onClick={() => logout()}>
                <SvgLock className="h-4 w-4" aria-hidden="true" />
                Log out
              </MenuItem>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
