import { twMerge } from "tailwind-merge";
import React, { useState } from "react";

const ICON_LENGTHS = {
  small: 30,
  medium: 40,
  large: 60,
} as const;

type IndicatedIconProps = {
  /** The icon that will be inserted in the middle of the indicator */
  Icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  /** The size of the icon. */
  iconSize?: "small" | "medium" | "large";
  /** Additional classes to be applied to the icon. Use text-[color] to change the color of the icon (only works when iconType='svg'). */
  iconClassName?: string;
  /** The background image that will be inserted in the middle of the indicator */
  image?: string;
  /** Additional classes to be applied to the image. */
  imageClassName?: string;
  /** Whether the indicator should be shown or not */
  showIndicator?: boolean;
  /** Whether the indicator should be shown on hover or not */
  showIndicatorOnHover?: boolean;
  /** Additional classes to be applied to the indicator. Use text-[color] to change the color of the indicator. */
  indicatorClassName?: string;
  /** Additional classes to be applied to the root element */
  className?: string;
  /** The function that will be called when the mouse is over the icon */
  onMouseOver?: () => void;
  /** The function that will be called when the mouse leaves the icon */
  onMouseLeave?: () => void;
};

/**
 * This component is used to show an icon with an indicator around it.
 * The indicator will be shown when `showIndicator` is true.
 * The icon will be inserted in the middle of the indicator. NOTE: It needs to be a square or it will be distorted.
 */
export const IndicatedIcon: React.FC<IndicatedIconProps> = ({
  Icon,
  iconSize = "medium",
  iconClassName = "",
  image,
  imageClassName = "",
  showIndicator = true,
  showIndicatorOnHover = false,
  indicatorClassName = "",
  className = "",
  onMouseOver = () => {},
  onMouseLeave = () => {},
}) => {
  const [hovering, setHovering] = useState(false);

  const indicatorShouldBeShown =
    (showIndicatorOnHover && hovering) || showIndicator;

  const indicatorScaleClass = indicatorShouldBeShown ? "scale-100" : "scale-0";
  const indicatorCircleDurationClass = indicatorShouldBeShown
    ? "duration-300"
    : "duration-500";
  const indicatorRectDurationClass = indicatorShouldBeShown
    ? "duration-500"
    : "duration-300";

  const iconStart = 50 - ICON_LENGTHS[iconSize] / 2;
  const iconLength = ICON_LENGTHS[iconSize];

  return (
    <svg
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <defs>
        <circle id="outer-circle" r={40} cx={50} cy={50} />
        <circle id="inner-circle" r={37.5} cx={50} cy={50} />
      </defs>
      {/* This is the hole in the middle of the indicator that will fit the icon */}
      <mask id="hole">
        <rect width="100%" height="100%" fill="white" />
        <use href="#inner-circle" fill="black" />
      </mask>
      {/* This is a reverse mask that removes everything around the hole */}
      <mask id="reverse-hole">
        <rect width="100%" height="100%" fill="black" />
        <use href="#inner-circle" fill="white" />
      </mask>
      {/* This is the indicator that will be shown around the icon */}
      <g
        mask="url(#hole)"
        className={twMerge(
          "fill-current transition-transform",
          indicatorClassName,
        )}
        fill="currentColor"
      >
        <use
          href="#outer-circle"
          className={twMerge(
            "origin-center delay-[inherit]",
            indicatorScaleClass,
            indicatorCircleDurationClass,
          )}
        />
        <polygon
          points="50,0 0,50 50,100 100,50"
          className={twMerge(
            "origin-center delay-[inherit]",
            indicatorScaleClass,
            indicatorRectDurationClass,
          )}
        />
      </g>
      {/* This is the background image that will be inserted */}
      {image && (
        <image
          mask="url(#reverse-hole)"
          href={image}
          width="100"
          height="100"
          className={imageClassName}
        />
      )}
      {/* This is the icon that will be inserted */}
      {Icon && (
        <Icon
          fill="currentColor"
          x={iconStart}
          y={iconStart}
          width={iconLength}
          height={iconLength}
          className={twMerge("transition-all", iconClassName)}
        />
      )}
      {/* These two elements make the icon only clickable/hoverable inside the circular area */}
      <rect
        width="100%"
        height="100%"
        fill="none"
        className="cursor-default"
        style={{
          visibility: "visible",
          pointerEvents: "visible",
        }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      />
      <use
        href="#outer-circle"
        fill="none"
        onMouseOver={() => {
          setHovering(true);
          onMouseOver();
        }}
        onMouseLeave={() => {
          setHovering(false);
          onMouseLeave();
        }}
        className="cursor-pointer"
        style={{
          visibility: "visible",
          pointerEvents: "visible",
        }}
      />
    </svg>
  );
};
