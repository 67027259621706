import { useModal } from "hooks/useModal";
import { TitleJost, TextPoppins } from "@brightstarstudios/components";
import Link from "next/link";
import { FormLogin } from "components/forms/FormLogin";
import SvgEmbersword from "assets/svgs/icons/embersword.svg";
import { useQueryClient } from "@tanstack/react-query";
import { User } from "types/account-api";
import { QUERY_KEYS } from "api/queryKeys";
import { displayError } from "utils/displayError";
import { useAuthCookie } from "hooks/useAuthCookie";
import { getModalRouteLink } from "utils/getModalRouteLink";
import { useRouter } from "next/router";
import { getHashParamFromUrl } from "@brightstarstudios/utilities";

// Only allow callback URLs to be these domains (besides current domain)
const WHITELISTED_CALLBACK_DOMAINS: string[] = [];

// Check if a URL matches any whitelisted domain
const isWhitelistedCallbackUrl = (url: string) => {
  if (url.startsWith("/")) {
    return true;
  }

  // Extract the domain from the URL
  const { hostname } = new URL(url);

  // Check if the domain is in the whitelist
  return WHITELISTED_CALLBACK_DOMAINS.some(
    (whitelistedDomain) => hostname === whitelistedDomain,
  );
};

export const ModalLogin: React.FC = () => {
  const { hideModal } = useModal();
  const { setAuthCookie } = useAuthCookie();
  const queryClient = useQueryClient();
  const router = useRouter();

  // Get the callback URL from the callback hash params
  const groupIdxString = getHashParamFromUrl("callback", router.asPath);
  const callback = groupIdxString && decodeURIComponent(groupIdxString);

  return (
    <div className="w-72 bg-white md:w-96">
      <div className="flex flex-col items-center justify-center bg-primary-red p-8 text-white">
        <SvgEmbersword width={75} height={125} />
        <TitleJost
          size="medium"
          variant="h1"
          className="mt-6 text-center font-extrabold uppercase"
        >
          Login
        </TitleJost>
      </div>
      <div className="px-4 py-8 lg:p-8">
        <FormLogin
          onLoginStart={(loginUserParams) => {
            // Optimistically set the user data to the temporary user data before the request is sent
            queryClient.setQueriesData<User>(
              { queryKey: [QUERY_KEYS.USER] },
              {
                email: loginUserParams.email,
              },
            );
            queryClient.setQueriesData(
              { queryKey: [QUERY_KEYS.ALLOWED_TO_PLAY] },
              false,
            );
          }}
          onLoginSuccess={(loginResponse) => {
            setAuthCookie(loginResponse.payload.accessToken);

            // If there's a callback url
            if (callback) {
              if (isWhitelistedCallbackUrl(callback)) {
                router.push(callback);
              } else {
                throw new Error("callback URL is not valid");
              }
            } else {
              hideModal();
            }
          }}
          onLoginError={(error) => {
            displayError(error);
            // If the request fails, we want to reset the user data
            queryClient.resetQueries({ queryKey: [QUERY_KEYS.USER] });
            queryClient.resetQueries({
              queryKey: [QUERY_KEYS.ALLOWED_TO_PLAY],
            });
          }}
        />

        <TextPoppins
          size="small"
          className="text-center uppercase text-tertiary-600/60"
        >
          Not a member?
          <Link
            href={getModalRouteLink("create-account")}
            className="mt-2 block text-center uppercase text-primary-red no-underline"
          >
            Create account
          </Link>
        </TextPoppins>
      </div>
    </div>
  );
};
