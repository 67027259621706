import { twMerge } from "tailwind-merge";
import React from "react";

const COLOR_CLASSNAMES = {
  GRAY: {
    CHECKED: "text-tertiary-600",
    UNCHECKED: "",
    DEFAULT: "bg-primary-grey",
  },
  WHITE: {
    CHECKED: "text-tertiary-600",
    UNCHECKED: "",
    DEFAULT: "bg-white",
  },
} as const;

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  /** The color the checkbox should be */
  color: "GRAY" | "WHITE";
}

/**
 * A checkbox component for forms.<br/>
 * Note that you have to pass `checked` and `onChange` and manage state yourself
 */
export const Checkbox: React.FC<CheckboxProps> = ({
  color,
  checked,
  disabled,
  className,
  ...rest
}) => {
  const checkedClassName = checked
    ? COLOR_CLASSNAMES[color].CHECKED
    : COLOR_CLASSNAMES[color].UNCHECKED;
  const defaultClassName = COLOR_CLASSNAMES[color].DEFAULT;
  const disabledStyling = disabled ? "cursor-not-allowed opacity-60" : "";

  return (
    <input
      type="checkbox"
      checked={checked}
      disabled={disabled}
      className={twMerge(
        "h-8 w-8 rounded-xl border-none outline-none ring-transparent focus:outline-none focus:ring-0 focus:ring-offset-0 focus-visible:ring-2 focus-visible:ring-primary-red",
        disabledStyling,
        checkedClassName,
        defaultClassName,
        className,
      )}
      {...rest}
    />
  );
};
