import { twMerge } from "tailwind-merge";
import React from "react";
import { Border } from "./Border";

export const BorderBox: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  className = "",
  children,
}) => {
  return (
    <div className={twMerge("relative overflow-hidden rounded-xl", className)}>
      {children}
      <Border className="pointer-events-none !absolute inset-5 text-inherit" />
    </div>
  );
};
