import { ModalType } from "types/modals";

export const getModalRouteLink = <T>(
  modalType: ModalType,
  modalContent?: T,
) => {
  let routeString = `modal=${modalType}`;

  if (modalContent) {
    routeString += `&modal-content=${encodeURIComponent(
      JSON.stringify(modalContent),
    )}`;
  }

  return {
    hash: routeString,
  };
};
