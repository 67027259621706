import { twMerge } from "tailwind-merge";
import React, { HTMLAttributes } from "react";
import SvgBorderCorner from "assets/svgs/decorations/border-corner.svg";
import SvgBorderTopTile from "assets/svgs/decorations/border-top-tile.svg";
import SvgBorderLineHorizontal from "assets/svgs/decorations/border-line-horizontal.svg";
import SvgBorderLineVertical from "assets/svgs/decorations/border-line-vertical.svg";

export const Border: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  className = "",
}) => (
  <div className={twMerge("relative p-4 text-white", className)}>
    {children}
    <div className="pointer-events-none absolute inset-0 flex flex-col">
      <div className="flex">
        <SvgBorderCorner />
        <SvgBorderLineHorizontal className="flex-1" />
        <SvgBorderTopTile className="translate-y-[-26%]" />
        <SvgBorderLineHorizontal className="flex-1" />
        <SvgBorderCorner className="rotate-90" />
      </div>
      <div className="flex min-h-0 flex-1 justify-between">
        <SvgBorderLineVertical />
        <SvgBorderLineVertical />
      </div>
      <div className="flex items-end">
        <SvgBorderCorner className="rotate-0 -scale-y-100" />
        <SvgBorderLineHorizontal className="flex-1" />
        <SvgBorderCorner className="rotate-90 -scale-x-100" />
      </div>
    </div>
  </div>
);
