import { clientConfig } from "config/client";
import { COOKIE_AUTH_KEY } from "hooks/useAuthCookie";
import {
  ActivateAccountResponseFailureSchema,
  ActivateAccountResponseSuccessSchema,
  CreateAccountResponseFailureSchema,
  CreateAccountResponseSuccessSchema,
  EnableGameplayResponseFailureSchema,
  EnableGameplayResponseSuccessSchema,
  ForgotPasswordResponseSchema,
  GetWalletResponseFailureSchema,
  GetWalletResponseSuccessSchema,
  LoginResponseFailedSchema,
  LoginResponseSuccessSchema,
  ResetPasswordResponseFailureSchema,
  ResetPasswordResponseSuccessSchema,
  SendSignatureResponseFailureSchema,
  SendSignatureResponseSuccessSchema,
  UpdateUserResponseFailureSchema,
  UpdateUserResponseSuccessSchema,
} from "schemas/account-api";
import {
  ActivateAccountParams,
  CreateAccountParams,
  EnableGameplayParams,
  ForgotPasswordParams,
  GetUserResponse,
  LoginUserParams,
  ResetPaswordParams,
  SendSignatureParams,
  UpdateUserParams,
} from "types/account-api";

export const loginUser = async (params: LoginUserParams) => {
  const url = `${clientConfig.esAuthApi}/login`;

  const res = await fetch(url, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "cache-control": "no-cache",
    },
    body: JSON.stringify(params),
  }).catch((error) => {
    throw new Error("Could not log in, because of a network error", {
      cause: error,
    });
  });

  if (!res.ok) {
    const failureBody = LoginResponseFailedSchema.safeParse(await res.json());
    if (!failureBody.success) {
      // The response from the auth api is not 200 and not the shape we expect
      throw new Error("Could not log in, because of an internal error", {
        cause: failureBody.error,
      });
    }
    // Throw the error from the auth api
    throw new Error(failureBody.data.message);
  }

  const successBody = LoginResponseSuccessSchema.safeParse(await res.json());
  if (!successBody.success) {
    // The response from the auth api is 200, but not the shape we expect
    throw new Error("Could not log in, because of an internal error", {
      cause: successBody.error,
    });
  }

  return successBody.data;
};

export const createAccount = async (params: CreateAccountParams) => {
  const url = `${clientConfig.esAuthApi}/register`;

  const res = await fetch(url, {
    method: "post",
    body: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json",
      "cache-control": "no-cache",
    },
  }).catch((error) => {
    throw new Error("Could not create account, because of a network error", {
      cause: error,
    });
  });

  if (!res.ok) {
    const failureBody = CreateAccountResponseFailureSchema.safeParse(
      await res.json(),
    );
    if (!failureBody.success) {
      // The response from the auth api is not 200 and not the shape we expect
      throw new Error(
        "Could not create account, because of an internal error",
        { cause: failureBody.error },
      );
    }
    // Throw the error from the auth api. If an account is registered. The server respons with internal server error.
    // So we create our own generic message
    throw new Error(
      "Unable to create account. You might already have an account registered",
    );
  }

  const successBody = CreateAccountResponseSuccessSchema.safeParse(
    await res.json(),
  );
  if (!successBody.success) {
    // The response from the auth api is 200, but not the shape we expect
    throw new Error("Could not create account, because of an internal error", {
      cause: successBody.error,
    });
  }
};

export const forgotPassword = async (params: ForgotPasswordParams) => {
  const url = `${clientConfig.esAuthApi}/forgot-password`;

  const res = await fetch(url, {
    method: "post",
    body: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json",
      "cache-control": "no-cache",
    },
  }).catch((error) => {
    throw new Error("Action failed, because of a network error", {
      cause: error,
    });
  });

  const body = ForgotPasswordResponseSchema.safeParse(await res.json());
  if (!body.success) {
    // The response from the auth api is not 200 and not the shape we expect
    throw new Error("Action failed, because of an internal error", {
      cause: body.error,
    });
  }
  if (!res.ok) {
    // Throw the error from the auth api
    throw new Error(body.data.message);
  }

  return body.data.message;
};

export const activateAccount = async (params: ActivateAccountParams) => {
  const url = `${clientConfig.esAuthApi}/activate`;

  const res = await fetch(url, {
    method: "post",
    body: JSON.stringify(params),
    headers: {
      "cache-control": "no-cache",
    },
  }).catch((error) => {
    throw new Error("Could not activate account, because of a network error", {
      cause: error,
    });
  });

  if (!res.ok) {
    const failureBody = ActivateAccountResponseFailureSchema.safeParse(
      await res.json(),
    );
    if (!failureBody.success) {
      // The response from the auth api is not 200 and not the shape we expect
      throw new Error(
        "Could not activate account, because of an internal error",
        { cause: failureBody.error },
      );
    }
    // Throw the error from the auth api
    throw new Error(failureBody.data.message);
  }

  const successBody = ActivateAccountResponseSuccessSchema.safeParse(
    await res.json(),
  );
  if (!successBody.success) {
    // The response from the auth api is 200, but not the shape we expect
    throw new Error(
      "Could not activate account, because of an internal error",
      { cause: successBody.error },
    );
  }
};

export const getUser = async (authCookie: string) => {
  const url = `${clientConfig.esAuthApi}/user`;

  const res = await fetch(url, {
    headers: {
      [COOKIE_AUTH_KEY]: authCookie,
    },
  });

  if (res.status !== 200) {
    throw new Error("Could not get user");
  }

  const data: GetUserResponse = await res.json();
  const payload = data.payload;

  return payload;
};

export const allowedToPlay = async (authCookie: string) => {
  const url = `${clientConfig.esAuthApi}/verify`;

  const res = await fetch(url, {
    headers: {
      [COOKIE_AUTH_KEY]: authCookie,
    },
  });

  // We don't care about the body, just the status code
  return res.status === 200;
};

export const revokeUserToken = async (authCookie: string) => {
  const url = `${clientConfig.esAuthApi}/logout`;

  try {
    const res = await fetch(url, {
      method: "post",
      headers: {
        [COOKIE_AUTH_KEY]: authCookie,
      },
    });

    const data = await res.json();

    return data;
  } catch (error) {
    throw error;
  }
};

export const updateUser = async (
  authCookie: string,
  params: UpdateUserParams,
) => {
  const url = `${clientConfig.esAuthApi}/user`;

  const res = await fetch(url, {
    method: "put",
    headers: {
      [COOKIE_AUTH_KEY]: authCookie,
    },
    body: JSON.stringify({ meta: { ...params } }),
  }).catch((error) => {
    throw new Error(
      "Could not update account information, because of a network error",
      { cause: error },
    );
  });

  if (!res.ok) {
    try {
      const failureBody = UpdateUserResponseFailureSchema.safeParse(
        await res.json(),
      );
      if (!failureBody.success) {
        // The response from the auth api is not 200 and not the shape we expect
        throw new Error(
          "Could not update account information, because of an internal error",
          { cause: failureBody.error },
        );
      }
      // Throw the error from the auth api
      throw new Error(failureBody.data.message);
    } catch (error) {
      throw new Error(
        "Could not update account information, because of an internal error",
        { cause: error },
      );
    }
  }

  const successBody = UpdateUserResponseSuccessSchema.safeParse(
    await res.json(),
  );
  if (!successBody.success) {
    // The response from the auth api is 200, but not the shape we expect
    throw new Error(
      "Could not update account information, because of an internal error",
      { cause: successBody.error },
    );
  }
};

export const getWalletMessage = async (authCookie: string) => {
  const url = `${clientConfig.esAuthApi}/wallet-data`;

  const res = await fetch(url, {
    headers: {
      [COOKIE_AUTH_KEY]: authCookie,
    },
  }).catch((error) => {
    throw new Error("Could not get wallet data, because of a network error", {
      cause: error,
    });
  });

  if (!res.ok) {
    const failureBody = GetWalletResponseFailureSchema.safeParse(
      await res.json(),
    );
    if (!failureBody.success) {
      // The response from the auth api is not 200 and not the shape we expect
      throw new Error(
        "Could not get wallet data, because of an internal error",
        { cause: failureBody.error },
      );
    }
    // Throw the error from the auth api
    throw new Error(failureBody.data.message);
  }

  const successBody = GetWalletResponseSuccessSchema.safeParse(
    await res.json(),
  );
  if (!successBody.success) {
    // The response from the auth api is 200, but not the shape we expect
    throw new Error("Could not get wallet data, because of an internal error", {
      cause: successBody.error,
    });
  }

  return successBody.data;
};

export const sendWalletSignature = async (
  authCookie: string,
  params: SendSignatureParams,
) => {
  const url = `${clientConfig.esAuthApi}/wallet-data`;

  const res = await fetch(url, {
    method: "post",
    body: JSON.stringify(params),
    headers: {
      [COOKIE_AUTH_KEY]: authCookie,
    },
  }).catch((error) => {
    throw new Error(
      "Could not send wallet signature, because of a network error",
      { cause: error },
    );
  });

  if (!res.ok) {
    const failureBody = SendSignatureResponseFailureSchema.safeParse(
      await res.json(),
    );
    if (!failureBody.success) {
      // The response from the auth api is not 200 and not the shape we expect
      throw new Error(
        "Could not send wallet signature, because of an internal error",
        { cause: failureBody.error },
      );
    }
    // Throw the error from the auth api
    throw new Error(failureBody.data.message);
  }

  const successBody = SendSignatureResponseSuccessSchema.safeParse(
    await res.json(),
  );
  if (!successBody.success) {
    // The response from the auth api is 200, but not the shape we expect
    throw new Error(
      "Could not send wallet signature, because of an internal error",
      { cause: successBody.error },
    );
  }
};

export const resetPassword = async (params: ResetPaswordParams) => {
  const url = `${clientConfig.esAuthApi}/info/update?code=${params.code}`;

  const res = await fetch(url, {
    method: "put",
    headers: {
      "Content-Type": "application/json",
      "cache-control": "no-cache",
    },
    body: JSON.stringify({
      password: params.password,
      passwordRepeated: params.passwordRepeated,
    }),
  }).catch((error) => {
    throw new Error("Could not reset password, because of a network error", {
      cause: error,
    });
  });

  if (!res.ok) {
    const failureBody = ResetPasswordResponseFailureSchema.safeParse(
      await res.json(),
    );
    if (!failureBody.success) {
      // The response from the auth api is not 200 and not the shape we expect
      throw new Error(
        "Could not reset password, because of an internal error",
        { cause: failureBody.error },
      );
    }
    // Throw the error from the auth api
    throw new Error(failureBody.data.message);
  }

  const successBody = ResetPasswordResponseSuccessSchema.safeParse(
    await res.json(),
  );
  if (!successBody.success) {
    // The response from the auth api is 200, but not the shape we expect
    throw new Error("Could not reset password, because of an internal error", {
      cause: successBody.error,
    });
  }
};

export const enableGameplay = async (
  authCookie: string,
  params: EnableGameplayParams,
) => {
  const url = `${clientConfig.esAuthApi}/enable-gameplay`;

  const res = await fetch(url, {
    method: "post",
    headers: {
      [COOKIE_AUTH_KEY]: authCookie,
    },
    body: JSON.stringify(params),
  }).catch((error) => {
    throw new Error("Could not submit promo code, because of a network error", {
      cause: error,
    });
  });

  if (!res.ok) {
    const failureBody = EnableGameplayResponseFailureSchema.safeParse(
      await res.json(),
    );
    if (!failureBody.success) {
      // The response from the auth api is not 200 and not the shape we expect
      throw new Error(
        "Could not capture promo code, because of an internal error",
        { cause: failureBody.error },
      );
    }
    // Throw the error from the auth api
    throw new Error(failureBody.data.message);
  }

  const successBody = EnableGameplayResponseSuccessSchema.safeParse(
    await res.json(),
  );
  if (!successBody.success) {
    // The response from the auth api is 200, but not the shape we expect
    throw new Error(
      "Could not capture promo code, because of an internal error",
      { cause: successBody.error },
    );
  }
};

export const deleteUser = async (authCookie: string) => {
  const url = `${clientConfig.esAuthApi}/deactivate`;

  const res = await fetch(url, {
    method: "post",
    headers: {
      [COOKIE_AUTH_KEY]: authCookie,
    },
  });

  if (res.status !== 200) {
    throw new Error(
      `Could not delete user, received status ${res.status} - ${res.statusText}`,
    );
  }

  const data: GetUserResponse = await res.json();
  const payload = data.payload;

  return payload;
};
