import { deleteUser } from "api/account";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useRouter } from "next/router";
import { useAuthCookie } from "./useAuthCookie";
import { QUERY_KEYS } from "api/queryKeys";
import { showToast } from "components/Toast";

export const useDeleteUser = () => {
  const router = useRouter();
  const { authToken, removeAuthCookie } = useAuthCookie();
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useMutation({
    mutationFn: async () => await deleteUser(authToken),
    onSuccess: async () => {
      showToast.info("Account succesfully deleted");
      removeAuthCookie();
      queryClient.setQueryData([QUERY_KEYS.USER], null);
      queryClient.setQueryData([QUERY_KEYS.ALLOWED_TO_PLAY], false);
      // Start navigation to home page before the request is sent
      router.replace("/");
    },
    onError: () => {
      showToast.error(
        `Sorry, we were unable to delete your account at this time. Please try again later or contact our support team if the issue persists.`,
      );
    },
  });

  return {
    deleteUser: mutate,
    ...rest,
  };
};
