import React from "react";
import { Modal } from "../Modal";
import { TextPoppins } from "@brightstarstudios/components";

export const ModalCreateAccountInfo: React.FC = () => (
  <Modal.Info>
    <div className="text-center text-tertiary-600">
      <TextPoppins className="mb-6" size="medium">
        <strong>IMPORTANT:</strong> To finish your registration you have to
        activate your account within the next <strong>2 minutes</strong>.
        Remember to check your spam folder.
      </TextPoppins>
      <TextPoppins size="medium">
        If you don&apos;t, you will not be registered or signed up to our
        newsletter. We do this to avoid spam.
      </TextPoppins>
    </div>
  </Modal.Info>
);
