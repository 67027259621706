import React from "react";
import { Modal } from "../Modal";
import { Prose } from "components/Prose";
import { ButtonAnimated } from "@brightstarstudios/components";
import { useDeleteUser } from "hooks/useDeleteUser";

export const ModalDeleteAccount: React.FC = () => {
  const { deleteUser, isPending } = useDeleteUser();

  return (
    <Modal.Info>
      <Prose className="text-center">
        <h3>Confirm delete account</h3>
        <p>Are you sure that you want to delete your account?</p>
        <p>
          Please note that you will be logged out of your account immediately
          after deleting it.
        </p>
      </Prose>

      <div className="flex justify-center">
        <ButtonAnimated
          color="red"
          label="Delete account"
          className="w-64"
          onClick={() => deleteUser()}
          disabled={isPending}
        />
      </div>
    </Modal.Info>
  );
};
