export enum QUERY_KEYS {
  USER = "USER",
  ACCOUNT_ACTIVATION = "ACCOUNT_ACTIVATION",
  ALLOWED_TO_PLAY = "ALLOWED_TO_PLAY",
  APPROVED_YOUTUBE_IDS = "APPROVED_YOUTUBE_IDS",
  CART = "CART",
  PLOT_MAP = "GROUP_AVAILABILITIES",
  IMX_USER_USDC_BALANCE = "IMX_USER_USDC_BALANCE",
  IMX_ACCOUNT = "IMX_ACCOUNT",
  IMX_SANDBOX_ACCOUNT = "IMX_SANDBOX_ACCOUNT",
  IMX_CHECKOUT_CART = "IMX_CHECKOUT_CART",
  PURCHASE_QUOTA = "PURCHASE_QUOTA",
  IMX_OWNER_BADGES = "IMX_OWNER_BADGES",
  IMX_OWNER_LAND = "IMX_OWNER_LAND",
  IMX_USDC_BALANCE = "IMX_USDC_BALANCE",
  CHECKOUT_IN_PROGRESS = "CHECKOUT_IN_PROGRESS",
  ULTRA_DEEP_MY_LEADERBOARD_STATS = "ULTRA_DEEP_MY_LEADERBOARD_STATS",
  GLOBAL_SETTINGS = "GLOBAL_SETTINGS",
  DELETE_USER = "DELETE_USER",
  USER_IS_BADGE_HOLDER = "USER_IS_BADGE_HOLDER",
}

export enum MUTATION_KEYS {
  CLEAR_CART = "CLEAR_CART",
}
