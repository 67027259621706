import { z } from "zod";

export const ImxAssetTypeSchema = z.union([
  z.literal("Regular plot"),
  z.literal("Settlement plot"),
  z.literal("Town plot"),
  z.literal("City plot"),

  z.literal("Bronze"),
  z.literal("Silver"),
  z.literal("Gold"),
  z.literal("Platinum"),
]);

export const ImxAssetMetadataSchema = z.object({
  id: z.string(),
  name: z.string(),
  type: ImxAssetTypeSchema,
  image_url: z.string(),
  description: z.string(),
});

export const ImxAssetSchema = z.object({
  token_address: z.string(),
  token_id: z.string(),
  id: z.string(),
  user: z.string(),
  status: z.string(),
  uri: z.string().nullable(),
  name: z.string(),
  description: z.string(),
  image_url: z.string(),
  metadata: ImxAssetMetadataSchema,
  collection: z.object({
    name: z.string(),
    icon_url: z.string(),
  }),
  created_at: z.string(),
  updated_at: z.string(),
});

export const ImxAssetsResponseSchema = z.object({
  result: z.array(ImxAssetSchema),
  cursor: z.string(),
  remaining: z.number(),
});

export const ImxUserBalanceSchema = z.object({
  balance: z.string(),
  preparing_withdrawal: z.string(),
  symbol: z.string(),
  token_address: z.string(),
  withdrawable: z.string(),
});

export const ImxUserResponseSuccessSchema = z.object({
  accounts: z.array(z.string()),
});

export const ImxUserResponseFailureSchema = z.object({
  code: z.string(),
  message: z.string(),
});

export const WalletAddressSchema = z.string().refine(
  (value) => {
    const regex = /^0x[a-fA-F0-9]{40}$/;
    return regex.test(String(value).toLowerCase());
  },
  { message: "Invalid wallet address" },
);
