import React, { useState } from "react";
import { Desktop } from "./Desktop";
import { Mobile } from "./Mobile";
import { IS_DEV_ENVIRONMENT } from "utils/isDevEnvironment";
import Link from "next/link";
import SvgLogo from "assets/svgs/logo.svg";
import { useUser } from "hooks/useUser";
import { NavBarButton } from "components/NavBarButton";
import { getModalRouteLink } from "utils/getModalRouteLink";
import { MenuAccount } from "components/MenuAccount";
import { TextPoppins } from "@brightstarstudios/components";
import { clientConfig } from "config/client";
import { GlobalBanner } from "components/GlobalBanner";

export const Header: React.FC = () => {
  const { user, isFetching } = useUser();

  const [showDevBanner, setShowDevBanner] = useState(true);

  return (
    <>
      {IS_DEV_ENVIRONMENT && showDevBanner && (
        <div className="fixed inset-x-0 top-16 z-50 flex items-center justify-center bg-primary-red p-1 text-white">
          <TextPoppins size="medium" className="my-0 uppercase">
            {clientConfig.environment} environment
          </TextPoppins>
          <button
            onClick={() => setShowDevBanner(false)}
            className="absolute right-2"
          >
            &times;
          </button>
        </div>
      )}
      <GlobalBanner />
      <header className="sticky left-0 top-0 z-50 h-16 w-full bg-white shadow-md">
        <nav className="relative flex h-full items-center justify-between gap-x-8 p-4">
          {/** Left side */}
          <div className="flex items-center gap-x-8">
            <Link href="/">
              <SvgLogo className="h-12 text-primary-red" />
            </Link>

            <Desktop />
            <Mobile />
          </div>

          {/** Right side */}
          <div className="flex items-center justify-end gap-x-8">
            {!user && !isFetching && (
              <Link
                href={getModalRouteLink("login")}
                shallow
                id="desktop-login-button"
              >
                <NavBarButton href="#" className="text-primary-red">
                  Login
                </NavBarButton>
              </Link>
            )}
            {user && <MenuAccount />}
          </div>
        </nav>
      </header>
    </>
  );
};
