import React from "react";
import Image from "next/image";
import { ModalImageContentSchema } from "types/modals";
import { useRouter } from "next/router";
import { useModal } from "hooks/useModal";
import { getHashParamFromUrl } from "@brightstarstudios/utilities";

export const ModalImage: React.FC = () => {
  const router = useRouter();
  const { hideModal } = useModal();

  const modalContentHashParam = getHashParamFromUrl(
    "modal-content",
    router.asPath,
  );

  // Check if we have a modal content in the URL hash params. If not, redirect to home page.
  if (!modalContentHashParam) {
    hideModal();
    return null;
  }

  const modalContentHashParamValue = JSON.parse(
    decodeURIComponent(modalContentHashParam),
  );
  const contentParsed = ModalImageContentSchema.safeParse(
    modalContentHashParamValue,
  );

  // Modal content is invalid. Redirect to home page.
  if (!contentParsed.success) {
    hideModal();
    return null;
  }
  const { title, src } = contentParsed.data;

  return (
    <div className="relative h-[calc(100vh-4rem)] w-full">
      {/* h-[calc(100vh-4rem)] is added because it is the height of the modal minus the margin. If it is set to just 100vh, there will be a y-scrollbar */}
      <Image
        src={src}
        alt={title}
        className="object-contain"
        fill
        sizes="(max-width: 768px) 100vw, 50vw"
      />
    </div>
  );
};
