import React from "react";
import { Modal } from "../Modal";
import { Prose } from "components/Prose";

export const ModalPageClosed: React.FC = () => (
  <Modal.Info>
    <Prose className="text-center">
      <h2>Page is unavailable</h2>
      <p>Will be back shortly</p>
      {/* <p>We&apos;re sorry, but the page you&apos;re trying to access is currently experiencing a high volume of traffic.</p>
      <p>Please try accessing the page again in a few minutes. We appreciate your patience and understanding.</p> */}
    </Prose>
  </Modal.Info>
);
