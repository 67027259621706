import { twMerge } from "tailwind-merge";
import { AnimatedPlaceholder } from "components/AnimatedPlaceholder";
import React from "react";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  color: "white" | "light-gray";
  placeholderClassName?: string;
  inputClassName?: string;
}
export const Input: React.FC<InputProps> = ({
  color,
  className = "",
  placeholderClassName = "",
  inputClassName = "",
  placeholder,
  disabled,
  value,
  ...rest
}) => {
  const colorStyling =
    color === "white" ? "bg-white" : "bg-primary-grey truncate";

  const disabledStyling = disabled ? "opacity-60" : "";

  return (
    <div
      className={twMerge(
        "relative w-full rounded-xl",
        colorStyling,
        disabledStyling,
        className,
      )}
    >
      <AnimatedPlaceholder
        placeholder={placeholder}
        className={twMerge("text-tertiary-600", placeholderClassName)}
        value={value as string}
      />
      <input
        className={twMerge(
          "w-full truncate rounded-xl border-none border-transparent bg-transparent p-5 font-poppins text-tertiary-600 outline-none placeholder:opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-red disabled:cursor-not-allowed",
          inputClassName,
        )}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        {...rest}
      />
    </div>
  );
};
