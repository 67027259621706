import React from "react";
import { Modal } from "../Modal";
import { Prose } from "components/Prose";
import { FormExchangeTokens } from "components/forms/FormExchangeTokens";
import { useModal } from "hooks/useModal";
import { delay } from "@brightstarstudios/utilities";

export const ModalBadgeExchangeForTokens: React.FC = () => {
  const { hideModal, showModal } = useModal();

  return (
    <Modal.Info>
      <Prose className="text-center">
        <h2>Redeem badge for tokens</h2>

        <p>
          The process of redeeming your badge(s) for tokens is irreversible.
        </p>
      </Prose>
      <FormExchangeTokens
        onSuccess={() => showModal("badge-burned-success")}
        onSubmitting={async () => {
          await delay(1000);
          hideModal();
        }}
      />
    </Modal.Info>
  );
};
