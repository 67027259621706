import { forgotPassword } from "api/account";
import { Form, Formik } from "formik";
import { displayError } from "utils/displayError";
import { useModal } from "hooks/useModal";
import { showToast } from "components/Toast";
import { Input } from "components/form-elements/Input";
import { AnimatedHeight } from "components/AnimatedHeight";
import { RecaptchaV2 } from "components/RecaptchaV2";
import { ButtonAnimated } from "@brightstarstudios/components";
import { EmailSchema } from "schemas/account-api";
import { useState } from "react";
import { FormErrors } from "components/form-elements/FormErrors";

const INITIAL_VALUES = {
  email: "",
  recaptcha: null,
} as const;

export const FormForgotPassword = () => {
  const { hideModal } = useModal();
  const [showRecaptcha, setShowRecaptcha] = useState(false);

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      validate={(values) => {
        const errors: Record<string, string> = {};

        const emailParse = EmailSchema.safeParse(values.email);
        if (!emailParse.success) {
          errors.email = emailParse.error.issues
            .map((issue) => issue.message)
            .join("\n");
        }

        return errors;
      }}
      onSubmit={async (values, { setFieldError }) => {
        if (!values.recaptcha) {
          setFieldError("recaptcha", "Please verify you are not a robot");
          return;
        }
        try {
          const message = await forgotPassword({
            email: values.email,
            recaptcha: values.recaptcha,
          });
          showToast.info(message);
          hideModal();
        } catch (error) {
          displayError(error);
        }
      }}
    >
      {({
        values,
        handleChange,
        isValid,
        isSubmitting,
        handleBlur,
        setFieldValue,
        errors,
        dirty,
        touched,
      }) => {
        const loadRecaptcha = isValid && dirty;

        return (
          <Form className="flex flex-col">
            <FormErrors errors={errors} touched={touched} />
            <Input
              color="light-gray"
              type="email"
              name="email"
              placeholder="E-mail"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <AnimatedHeight
              show={showRecaptcha}
              className="mt-6 flex justify-center overflow-hidden"
            >
              {loadRecaptcha && (
                <RecaptchaV2
                  className="mt-4"
                  theme="light"
                  asyncScriptOnLoad={() => setShowRecaptcha(true)}
                  onChange={(token) => {
                    setFieldValue("recaptcha", token);
                  }}
                  onExpired={() => {
                    setFieldValue("recaptcha", null);
                  }}
                />
              )}
            </AnimatedHeight>

            <div className="flex justify-center">
              <ButtonAnimated
                color="red"
                type="submit"
                disabled={!isValid || isSubmitting || !dirty}
                className="my-4"
                label={isSubmitting ? "Waiting..." : "Send"}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
