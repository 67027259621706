import React from "react";
import { toast, ToastOptions } from "react-toastify";
import { Border } from "./Border";
import { TextPoppins } from "@brightstarstudios/components";

interface ToastProps {
  message: React.ReactNode;
  variant: "error" | "info" | "warning";
}

const Toast: React.FC<ToastProps> = ({ message, variant }) => {
  const textClass =
    variant === "error" ? "text-primary-red" : "text-tertiary-600";
  const borderClass =
    variant === "error" ? "text-primary-red" : "text-extra-100";

  return (
    <Border className={borderClass}>
      <TextPoppins size="medium" className={`m-2 ${textClass}`}>
        <span className="font-bold capitalize">{variant}: </span>
        {message}
      </TextPoppins>
    </Border>
  );
};

export const error = (message: React.ReactNode, options?: ToastOptions) =>
  toast(<Toast variant="error" message={message} />, options);

export const info = (message: React.ReactNode, options?: ToastOptions) =>
  toast(<Toast variant="info" message={message} />, options);
export const warning = (message: React.ReactNode, options?: ToastOptions) =>
  toast(<Toast variant="warning" message={message} />, options);

export const showToast = {
  error,
  info,
  warning,
};
