import React from "react";
import { useFormik } from "formik";
import { Checkbox } from "components/form-elements/Checkbox";
import { delay, getHashParamFromUrl } from "@brightstarstudios/utilities";
import { ModalTransferImxBatchNftSchema } from "types/modals";
import { ERC721TokenType } from "@imtbl/imx-sdk";
import { link } from "hooks/useImx";
import { displayError } from "utils/displayError";
import { useRouter } from "next/router";
import { ButtonAnimated, ButtonSimple } from "@brightstarstudios/components";
import Link from "next/link";
import { postBurnBadgesSuccess } from "api/burn-badges-success";
import { useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "api/queryKeys";
import { badgesExceedUsdcThreshold } from "utils/badgesExceedUsdcThreshold";

type FormExchangeTokensFormValues = { consent1: boolean; consent2: boolean };
type BurnResponse = {
  status: string;
  toAddress: string;
  tokenAddress: string;
  tokenId: string;
  txId: number;
  type: ERC721TokenType.ERC721;
};

interface FormExchangeTokensProps {
  onSuccess: (burnResponse: BurnResponse[]) => void;
  onSubmitting: () => void;
}

export const FormExchangeTokens: React.FC<FormExchangeTokensProps> = ({
  onSuccess,
  onSubmitting,
}) => {
  const router = useRouter();
  const queryClient = useQueryClient();
  const formik = useFormik<FormExchangeTokensFormValues>({
    initialValues: {
      consent1: false,
      consent2: false,
    },
    onSubmit: async (values, { setSubmitting }) => {
      onSubmitting();
      try {
        const modalContentHashParam = getHashParamFromUrl(
          "modal-content",
          router.asPath,
        );

        // Check if we have a modal content in the URL hash params. If not, redirect to home page.
        if (!modalContentHashParam) {
          throw "Invalid asset information";
        }

        const modalContentHashParamValue = JSON.parse(
          decodeURIComponent(modalContentHashParam),
        );

        const contentParsed = ModalTransferImxBatchNftSchema.safeParse(
          modalContentHashParamValue,
        );

        // Modal content is invalid. Redirect to home page.
        if (!contentParsed.success) {
          throw "Invalid asset information. Missing asset id or asset address";
        }
        const assets = contentParsed.data.map((asset) => ({
          ...asset,
          type: ERC721TokenType.ERC721,
          toAddress: "0x0000000000000000000000000000000000000000",
        }));

        const { result } = await link.transfer(assets);
        if (result[0].status === "success") {
          result[0].txId;
        }

        const successTransfers = result.filter(
          ({ status }) => status === "success",
        ) as { tokenId: string; txId: number }[];

        const successTokenIds = successTransfers.map(({ tokenId }) => tokenId);
        const txIds = successTransfers.map(({ txId }) => txId.toString());

        // Delay since refetch of assets can show old result if fetched right away
        await delay(1500);

        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.IMX_OWNER_BADGES],
        });

        // If above threshold user should KYC
        const shouldKyc = badgesExceedUsdcThreshold(successTokenIds);

        await postBurnBadgesSuccess(txIds, shouldKyc);

        onSuccess(result as BurnResponse[]);
      } catch (error) {
        displayError(error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const consentsAccepted = formik.values.consent1 && formik.values.consent2;

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="font-jost font-medium text-tertiary-600"
    >
      <label className="cursor-pointer">
        <div className="mt-6 flex">
          <Checkbox
            color="GRAY"
            name="consent1"
            onChange={formik.handleChange}
            checked={formik.values.consent1}
          />
          <p className="ml-4 text-sm">
            I understand that by starting this redemption process, I will be
            burning my badge and will begin my $EMBER token release process and
            agree to undergo any necessary KYC (Know Your Customer) checks as
            part of compliance procedures.
          </p>
        </div>
      </label>
      <label className="cursor-pointer">
        <div className="mt-6 flex">
          <Checkbox
            color="GRAY"
            name="consent2"
            onChange={formik.handleChange}
            checked={formik.values.consent2}
          />
          <p className="ml-4 text-sm">
            As stated in the terms and conditions, we were not able to sell or
            redeem badges to users from restricted geographical areas due to
            local regulations. I&apos;ve read through the terms and that I am
            NOT located in the restricted areas. Please see full terms{" "}
            <ButtonSimple color="blue">
              <Link href="/terms-and-conditions" className="lowercase">
                here
              </Link>
            </ButtonSimple>
            .
          </p>
        </div>
      </label>

      <div className="flex justify-center">
        <ButtonAnimated
          color="red"
          disabled={formik.isSubmitting || !consentsAccepted}
          className="mt-6 w-56"
          label="Redeem"
          type="submit"
        />
      </div>
    </form>
  );
};
