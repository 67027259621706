import { clientConfig } from "config/client";
import { z } from "zod";

export const ModalTypeSchema = z.enum([
  "login",
  "create-account",
  "forgot-password",
  "newsletter-signup",
  "account-activation",
  "create-account-info",
  "video",
  "image",
  "newsletter-interest",
  "reset-password",
  "resubscribe-newsletter",
  "page-closed",
  "beta-disclaimer",
  "delete-account",
  "asset-transfer",
  "badge-exchange-tokens",
  "badge-burned-success",
]);

export type ModalType = z.infer<typeof ModalTypeSchema>;

export const ModalImageContentSchema = z.object({
  // src is a url but it can be relative. We cant use z.string().url() here
  src: z.string(),
  title: z.string(),
});
export type ModalImageContent = z.infer<typeof ModalImageContentSchema>;

export const ModalVideoContentSchema = z.object({
  src: z.string().url(),
  ratio: z.string(),
});
export type ModalVideoContent = z.infer<typeof ModalVideoContentSchema>;

export const NewsletterInterestSchema = z.enum(["team", "lore", "gameplay"]);
export type NesletterInterest = z.infer<typeof NewsletterInterestSchema>;
export const ModalNewsletterInterestContentSchema = z.object({
  interest: NewsletterInterestSchema,
});
export type ModalNewsletterInterestContent = z.infer<
  typeof ModalNewsletterInterestContentSchema
>;

export const UltraDeepGameLinksSchema = z.union([
  z.literal(clientConfig.gameLinkEurope),
  z.literal(clientConfig.gameLinkNorthAmerica),
  z.literal(clientConfig.gameLinkSouthAmerica),
  z.literal(clientConfig.gameLinkSouthEastAsia),
]);

const ModalTransferImxNftSchema = z.object({
  tokenId: z.string(),
  tokenAddress: z.string(),
});

export const ModalTransferImxBatchNftSchema = z.array(
  ModalTransferImxNftSchema,
);
