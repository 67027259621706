import React, { useState } from "react";
import { Modal } from "../Modal";
import { Prose } from "components/Prose";
import { ButtonAnimated } from "@brightstarstudios/components";
import { useRouter } from "next/router";
import { UltraDeepGameLinksSchema } from "types/modals";
import { useAllowedToPlay } from "hooks/useAllowedToPlay";
import { useModal } from "hooks/useModal";
import { getHashParamFromUrl } from "@brightstarstudios/utilities";

import {
  isChrome,
  browserVersion,
  isEdge,
  isMobile,
  isWindows,
} from "react-device-detect";
import { displayRequirementsNotMetToast } from "utils/displayRequirementsNotMetToast";
import { useUser } from "hooks/useUser";
import Link from "next/link";

export const ModalBetaDisclaimer: React.FC = () => {
  const [seenRequirements, setSeenRequirements] = useState(false);
  const router = useRouter();
  const { data: allowedToPlay } = useAllowedToPlay();
  const { hideModal } = useModal();
  const { user } = useUser();

  const modalContentHashParam = getHashParamFromUrl(
    "modal-content",
    router.asPath,
  );

  // Check if we have a modal content in the URL hash params. If not, redirect to home page.
  if (!modalContentHashParam) {
    hideModal();
    return null;
  }

  const modalContentHashParamValue = JSON.parse(
    decodeURIComponent(modalContentHashParam),
  );

  const contentParsed = UltraDeepGameLinksSchema.safeParse(
    modalContentHashParamValue,
  );

  // Modal content is invalid. Redirect to home page.
  if (!contentParsed.success) {
    hideModal();
    return null;
  }

  const onPlayClick = () => {
    if (seenRequirements || (supportedBrowser && supportedDevice)) {
      router.push(contentParsed.data);
    } else {
      if (!supportedBrowser || !supportedDevice) {
        displayRequirementsNotMetToast();
        setSeenRequirements(true);
      }
    }
  };

  const supportedBrowser =
    (isChrome || isEdge) && Number(browserVersion) >= 108;
  const supportedDevice = !isMobile && isWindows;

  return (
    <Modal.Info>
      <Prose className="text-center">
        <h3>Ember Sword is in Beta</h3>
        <p>
          Welcome to Ember Sword: Prologue! Please be aware that you might
          encounter bugs, and parts of the game which are bit rough and
          unpolished. For the best experience, we strongly recommend using a
          Windows PC with up-to-date versions of Chrome or Edge.
        </p>
        <p>
          We&apos;re continuously working to make Ember Sword better, and your
          feedback is crucial in this process, so please let us know what you
          think via Discord or our social channels.
        </p>
        <p>
          Thank you for your support and patience as we work to improve
          everything, GLHF!
        </p>

        <h4>Requirements</h4>
        <p className="text-left">
          {supportedBrowser && `✅ Browser supported`}
          {!supportedBrowser &&
            `❌ Browser NOT supported. Works with Google Chrome and Microsoft Edge version 108 or newer`}
          <br />
          {supportedDevice && `✅ Device supported`}
          {!supportedDevice &&
            `❌ Device NOT supported. Works with Windows PCs only`}
        </p>

        {!user?.walletAddress && (
          <>
            <h4>Recommended</h4>
            <p>
              To receive premium test cosmetics (NFTs) during the beta test, you
              must have a verified wallet registered with your Ember Sword
              account.
            </p>

            <p className="text-left">
              {!user?.walletAddress &&
                `❌ Wallet NOT verified on Ember Sword account`}
            </p>
            <Link href="/account#verify-account">
              <ButtonAnimated
                color="blue"
                className="w-56"
                label="Verify wallet"
              />
            </Link>
          </>
        )}
      </Prose>

      {allowedToPlay && (
        <div className="mt-12 flex justify-center">
          <ButtonAnimated
            color="red"
            label={seenRequirements ? "Play anyway" : "Got it"}
            className="w-56"
            onClick={onPlayClick}
          />
        </div>
      )}
    </Modal.Info>
  );
};
