import { NewsLetterSubmission } from "types/newsletter";

/**
 * Subscribe email to the newsletter
 * @param { email, recaptchaToken } - email and recaptcha token
 * @returns { boolean } - true if signup was successful, false if not
 */
export const subscribeToNewsLetter = async ({
  email,
  recaptchaToken,
}: NewsLetterSubmission): Promise<boolean> => {
  const data = {
    s: "IGLEFk_JBH3KBxcAAGLEFk9J0hFJcx-TBrGsw8Pzm0bK0M3k3A",
    cw: {
      widget_id: "62c2c3d7146584f9825a63d6",
      type: "widget_form_submitted",
      attributes: [
        { field_id: "str::email", value: email, merge_strategy: "override" },
      ],
      rc: recaptchaToken,
    },
    e: email,
    h: "YdawGzhT7gSzT6t2YnJpZ2h0c3RhcnN0dWRpb3M",
  };

  try {
    const res = await fetch(`/api/newsletter-signup`, {
      method: "post",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "text/plain;charset=UTF-8",
        "cache-control": "no-cache",
      },
    });

    return res.status === 200;
  } catch (error) {
    return false;
  }
};
