import React from "react";
import Link from "next/link";
import { PRIMARY_MENU_ITEMS } from "../../../data/nav-data";
import { NavBarButton } from "../../NavBarButton";

export const Desktop = () => {
  return (
    <div className="hidden gap-x-8 lg:flex">
      {PRIMARY_MENU_ITEMS.map(({ name, external, link }) => {
        const externalProps = external && {
          target: "_blank",
          rel: "noreferrer noopener",
        };

        return (
          <Link
            key={link}
            href={link}
            {...externalProps}
            className="focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-red"
          >
            <NavBarButton href={link}>{name}</NavBarButton>
          </Link>
        );
      })}
    </div>
  );
};
