import { useState } from "react";
import { TextPoppins } from "@brightstarstudios/components";
import { useGlobalSettings } from "hooks/useGlobalSettings";

export const GlobalBanner = () => {
  const { data } = useGlobalSettings();
  const { globalBanner } = data;
  const [hide, setHide] = useState(false);

  if (!globalBanner.show || globalBanner.text.length === 0 || hide) {
    return null;
  }

  return (
    <div className="fixed inset-x-0 top-16 z-50 flex items-center justify-center bg-primary-red p-2 px-7 text-white">
      <TextPoppins size="medium" className="my-0 text-center uppercase">
        {globalBanner.text}
      </TextPoppins>
      {globalBanner.userCanHide && (
        <button onClick={() => setHide(true)} className="absolute right-2">
          &times;
        </button>
      )}
    </div>
  );
};
