import { useRouter } from "next/router";
import { useEffect } from "react";

/**
 * Toggles the scroll-smooth Tailwind class on the documentElement
 * @param isSmooth - Whether to enable or disable smooth scrolling
 */
const setTailwindSmoothScroll = (isSmooth: boolean) => {
  document.documentElement.classList.toggle("scroll-smooth", isSmooth);
};

/**
 * This hook will toggle the scroll-smooth class on the documentElement when a route change is triggered
 * @example
 * const Component = () => {
 *  useSmoothScroll()
 * return <div>...</div>
 * }
 */
export function useTailwindSmoothScroll() {
  const router = useRouter();

  useEffect(() => {
    setTailwindSmoothScroll(true);
    const handleStart = () => setTailwindSmoothScroll(false);
    const handleStop = () => setTailwindSmoothScroll(true);

    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleStop);
    router.events.on("routeChangeError", handleStop);

    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleStop);
      router.events.off("routeChangeError", handleStop);
    };
  }, [router]);
}
