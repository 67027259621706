type MenuItem = {
  name: string;
  link: string;
  external: boolean;
};

export const PRIMARY_MENU_ITEMS: MenuItem[] = [
  {
    name: "Home",
    link: "/",
    external: false,
  },
  {
    name: "News",
    link: "/news",
    external: false,
  },
  {
    name: "About",
    link: "/about",
    external: false,
  },
  {
    name: "showcase",
    link: "/showcase",
    external: false,
  },
  {
    name: "Gallery",
    link: "/gallery",
    external: false,
  },
  {
    name: "Careers",
    link: "/careers",
    external: false,
  },
];

export const FOOTER_MENU_ITEMS: MenuItem[] = [
  {
    name: "Home",
    link: "/",
    external: false,
  },
  {
    name: "News",
    link: "/news",
    external: false,
  },
  {
    name: "About",
    link: "/about",
    external: false,
  },
  {
    name: "ember token",
    link: "/news/token",
    external: false,
  },
  {
    name: "canavar leaderboard",
    link: "/canavar-leaderboard",
    external: false,
  },
  {
    name: "showcase",
    link: "/showcase",
    external: false,
  },
  {
    name: "Ultra Deep",
    link: "/ultra-deep",
    external: false,
  },
  {
    name: "Gallery",
    link: "/gallery",
    external: false,
  },
  {
    name: "Careers",
    link: "/careers",
    external: false,
  },
  {
    name: "Social",
    link: "/social",
    external: false,
  },
  {
    name: "Thanabus cup",
    link: "/thanabus-cup",
    external: false,
  },
  {
    name: "Faq",
    link: "/faq",
    external: false,
  },
  {
    name: "Presskit",
    link: "/press-kit",
    external: false,
  },
  {
    name: "Whitepaper",
    link: "/whitepaper",
    external: false,
  },
  {
    name: "Terms and conditions",
    link: "/terms-and-conditions",
    external: false,
  },
  {
    name: "Code of conduct",
    link: "/code-of-conduct",
    external: false,
  },
  {
    name: "Privacy policy",
    link: "/privacy-policy",
    external: false,
  },
  {
    name: "Bright Star Studios",
    link: "https://brightstar.studio/",
    external: true,
  },
];
