import React from "react";
import { Header } from "components/globals/header/Header";
import { Footer } from "components/globals/footer/Footer";
import { JOST, POPPINS } from "styles/next-fonts";

export const Layout: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => (
  <>
    <Header />
    {/* These classes makes Next load the font on all pages that use this layout */}
    <div className={`${JOST.variable} ${POPPINS.variable}`}>{children}</div>
    <Footer />
  </>
);
