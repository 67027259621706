import { QUERY_KEYS } from "api/queryKeys";
import { useQuery } from "@tanstack/react-query";
import { getGlobalSettings } from "api/settings";
import { GLOBAL_SETTINGS_DEFAULTS } from "data/default-global-settings";

export const useGlobalSettings = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.GLOBAL_SETTINGS],
    queryFn: getGlobalSettings,
    initialData: GLOBAL_SETTINGS_DEFAULTS,
    refetchInterval: 1000 * 60, // 1 min
  });
};
